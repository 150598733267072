import React, { useEffect, useState } from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Button,
    Container,
    Box,
    Menu,
    MenuItem,
    Avatar,
    Tooltip,
    Divider,
    ListItemIcon,
    useScrollTrigger,
    Fade,
    Drawer,
    Paper
} from '@mui/material';
import {
    AccountCircle,
    Dashboard as DashboardIcon,
    Stream as StreamIcon,
    VideoLibrary as VideoIcon,
    Receipt as BillingIcon,
    Settings as SettingsIcon,
    Logout as LogoutIcon,
    PersonOutline as ProfileIcon,
    Wallet
} from '@mui/icons-material';
import { createTheme, ThemeProvider, alpha, useTheme } from '@mui/material/styles';
import SlotsScreen from './SlotsScreen';
import VideoUpload from './VideoUpload';
import Dashboard from './Dashboard';
import { MenuIcon } from 'lucide-react';
import Billing from './Billing';
import WalletScreen from "./WalletScreen"; // Import Wallet Component
import ProfileScreen from './ProfileScreen';



// Create a custom theme with bolder typography
const theme = createTheme({
    typography: {
        fontFamily: [
            'Inter',
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Helvetica Neue',
            'Arial',
            'sans-serif'
        ].join(','),
        h5: {
            fontWeight: 800,  // Increased from 700
            letterSpacing: '-0.03em',  // Slightly tighter tracking
            fontSize: '1.5rem'
        },
        h6: {
            fontWeight: 700,
            letterSpacing: '-0.02em'
        },
        body1: {
            fontWeight: 500,  // Medium weight for better readability
            letterSpacing: '-0.01em'
        },
        body2: {
            fontWeight: 500,
            letterSpacing: '-0.01em'
        },
        button: {
            fontWeight: 600,  // Increased from 500
            letterSpacing: '0.02em',
            fontSize: '0.95rem'  // Slightly larger button text
        },
        subtitle1: {
            fontWeight: 600,
            letterSpacing: '-0.01em'
        },
        subtitle2: {
            fontWeight: 600,
            letterSpacing: '-0.01em'
        }
    },
    shape: {
        borderRadius: 12
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    backdropFilter: 'blur(12px)'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    padding: '8px 16px',
                    fontWeight: 600  // Ensuring buttons are consistently bold
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontWeight: 500  // Making menu items slightly bolder
                }
            }
        }
    }
});

// Navigation items configuration
const navItems = [
    { name: 'Dashboard', icon: DashboardIcon },
    { name: 'Streams', icon: StreamIcon },
    { name: 'Videos', icon: VideoIcon },
    { name: 'Billing', icon: BillingIcon },
];


const Header = ({ onClick, activeScreen, toggleWallet, onProfileClick = () => { } }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const theme = useTheme();

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    });

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    return (
        <Container maxWidth="xl">
            <Paper
                elevation={0}
                sx={{
                    minWidth: '100%',
                    borderRadius: '0 0 40px 40px',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    backdropFilter: 'blur(10px)',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex'
                }}>
                <Toolbar
                    disableGutters
                    sx={{
                        transition: 'all 0.3s ease'
                    }}
                >
                    <IconButton
                        sx={{
                            display: { xs: 'flex', md: 'none' },
                            ml: 'auto',
                            mr: 1,
                            color: 'text.primary',
                            '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.main, 0.08)
                            }
                        }}
                        onClick={toggleMobileMenu}
                    >
                        <MenuIcon />
                    </IconButton>

                    {/* Logo */}
                    <Typography
                        variant="h5"
                        sx={{
                            background: 'linear-gradient(45deg, #6366F1 30%, #8B5CF6 90%)',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            color: 'transparent',
                            mr: { xs: 1, md: 4 },
                            fontWeight: 900,
                            fontSize: { xs: '1.5rem', sm: '1.75rem' },
                            letterSpacing: '-0.5px',
                            flexGrow: 1,
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                transform: 'scale(1.05)'
                            }
                        }}
                    >
                        StreamNeo
                    </Typography>
                    <div style={{ width: '100px', height: '10px' }} />

                    {/* Desktop Navigation */}
                    <Box sx={{
                        flexGrow: 1,
                        display: { xs: 'none', md: 'flex' },
                        gap: 0.5
                    }}>
                        {navItems.map((item, index) => {
                            const Icon = item.icon;
                            return (
                                <Button
                                    key={item.name}
                                    onClick={() => onClick(index)}
                                    sx={{
                                        px: 2,
                                        py: 1,
                                        position: 'relative',
                                        fontWeight: 600,
                                        color: activeScreen === index ? 'primary.main' : 'text.primary',
                                        overflow: 'hidden',
                                        '&::before': {
                                            content: '""',
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '2px',
                                            backgroundColor: 'primary.main',
                                            transform: activeScreen === index
                                                ? 'translateX(0)'
                                                : 'translateX(-100%)',
                                            transition: 'transform 0.3s ease'
                                        },
                                        '&:hover': {
                                            backgroundColor: alpha(theme.palette.primary.main, 0.08),
                                            '&::before': {
                                                transform: 'translateX(0)'
                                            }
                                        }
                                    }}
                                >
                                    <Icon sx={{ mr: 1, fontSize: 20 }} />
                                    {item.name}
                                </Button>
                            );
                        })}
                    </Box>

                    <Drawer
                        anchor="left"
                        open={mobileMenuOpen}
                        onClose={() => setMobileMenuOpen(false)}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                            '& .MuiDrawer-paper': { width: 240 }
                        }}
                    >
                        <Box sx={{ p: 2 }}>
                            <Typography
                                variant="h6"
                                sx={{
                                    background: 'linear-gradient(45deg, #6366F1, #8B5CF6)',
                                    backgroundClip: 'text',
                                    WebkitBackgroundClip: 'text',
                                    color: 'transparent',
                                    fontWeight: 900,
                                    mb: 2
                                }}
                            >
                                StreamNeo
                            </Typography>
                            {navItems.map((item, index) => {
                                const Icon = item.icon;
                                return (
                                    <MenuItem
                                        key={item.name}
                                        onClick={() => {
                                            onClick(index);
                                            setMobileMenuOpen(false);
                                        }}
                                        sx={{
                                            py: 1,
                                            px: 2,
                                            color: activeScreen === index ? 'primary.main' : 'text.primary',
                                            fontWeight: 600
                                        }}
                                    >
                                        <Icon sx={{ mr: 2 }} />
                                        {item.name}
                                    </MenuItem>
                                );
                            })}

                            {/* Added Profile option to mobile menu */}
                            <MenuItem
                                onClick={() => {
                                    onProfileClick();
                                    setMobileMenuOpen(false);
                                }}
                                sx={{
                                    py: 1,
                                    px: 2,
                                    color: activeScreen === 4 ? 'primary.main' : 'text.primary',
                                    fontWeight: 600
                                }}
                            >
                                <ProfileIcon sx={{ mr: 2 }} />
                                Profile
                            </MenuItem>
                        </Box>
                    </Drawer>

                    <div style={{ width: '100px', height: '10px' }} />
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        sx: { ml: 1 },
                        gap: { xs: 1, sm: 2, }
                    }}>
                        <Fade in timeout={1000}>
                            <Box sx={{
                                display: { xs: 'none', sm: 'flex' },
                                alignItems: 'center',
                                gap: 1,
                                backgroundColor: alpha(theme.palette.primary.main, 0.08),
                                py: 1,
                                px: 2,
                                borderRadius: 2
                            }}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: 'text.secondary',
                                        fontWeight: 500
                                    }}
                                >
                                    Welcome,
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 600,
                                        background: 'linear-gradient(45deg, #6366F1 30%, #8B5CF6 90%)',
                                        backgroundClip: 'text',
                                        WebkitBackgroundClip: 'text',
                                        color: 'transparent'
                                    }}
                                >
                                    {localStorage.getItem("name").split(' ')[0]}
                                </Typography>
                            </Box>
                        </Fade>

                        <Tooltip title="Account settings">
                            <IconButton
                                onClick={handleMenuOpen}
                                size="small"
                                sx={{
                                    border: '2px solid',
                                    borderColor: alpha(theme.palette.primary.main, 0.2),
                                    p: 0.5,
                                    transition: 'all 0.2s ease',
                                    '&:hover': {
                                        borderColor: 'primary.main',
                                        transform: 'scale(1.05)'
                                    }
                                }}
                            >
                                <Avatar
                                    sx={{
                                        width: 32,
                                        height: 32,
                                        background: 'linear-gradient(45deg, #6366F1 30%, #8B5CF6 90%)',
                                        fontWeight: 700
                                    }}
                                >
                                    {localStorage.getItem("name")[0]}
                                </Avatar>
                            </IconButton>
                        </Tooltip>

                        {/* Profile Menu */}
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            onClick={handleMenuClose}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    mt: 1.5,
                                    minWidth: 200,
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
                                    borderRadius: 2,
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                    '& .MuiMenuItem-root': {
                                        px: 2,
                                        py: 1.5,
                                        gap: 1.5,
                                        fontWeight: 500,
                                        transition: 'all 0.2s ease',
                                        '&:hover': {
                                            backgroundColor: alpha(theme.palette.primary.main, 0.08)
                                        }
                                    }
                                }
                            }}
                        >
                            <MenuItem onClick={() => {
                                toggleWallet();
                                handleMenuClose();
                            }}>
                                <ListItemIcon>
                                    <Wallet fontSize="small" />
                                </ListItemIcon>
                                Wallet
                            </MenuItem>
                            {/* Updated Profile menu item to use onProfileClick */}
                            <MenuItem onClick={() => {
                                onProfileClick();
                                handleMenuClose();
                            }}>
                                <ListItemIcon>
                                    <ProfileIcon fontSize="small" />
                                </ListItemIcon>
                                Profile
                            </MenuItem>
                            {/* <MenuItem>
                                <ListItemIcon>
                                    <SettingsIcon fontSize="small" />
                                </ListItemIcon>
                                Settings
                            </MenuItem> */}
                            <Divider sx={{ my: 1 }} />
                            <MenuItem sx={{
                                color: 'error.main',
                                fontWeight: 600,
                                '&:hover': {
                                    backgroundColor: alpha(theme.palette.error.main, 0.08)
                                }
                            }}
                                onClick={() => {
                                    localStorage.clear();
                                    window.location.reload();
                                }}
                            >
                                <ListItemIcon>
                                    <LogoutIcon fontSize="small" color="error" />
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Paper>
        </Container>
    );
};


const Home = ({ userName = "Prasun" }) => {
    const [screen, setScreen] = useState(0);
    const [walletOpen, setWalletOpen] = useState(false);
    const [walletBalance, setWalletBalance] = useState(0);

    useEffect(() => {
        // Tawk.to Integration
        const loadTawkTo = () => {
            // Dynamically create script element for Tawk.to
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://embed.tawk.to/67e1ba938283f3190cf67bd8/1in4s1l0i';
            script.charset = 'UTF-8';
            script.setAttribute('crossorigin', '*');

            // Append script to document
            document.body.appendChild(script);

            // Create style element for Tawk.to positioning
            const style = document.createElement('style');
            style.textContent = `
                #tawk-container {
                    right: 20px !important;
                }
                #tawk-mobile {
                    right: 20px !important;
                }
            `;
            document.head.appendChild(style);

            // Optional: Clean up function to remove script if component unmounts
            return () => {
                document.body.removeChild(script);
                document.head.removeChild(style);
                // Reset Tawk.to if needed
                if (window.Tawk_API) {
                    window.Tawk_API.endChat();
                }
            };
        };

        // Load Tawk.to script
        const cleanup = loadTawkTo();

        // Initial wallet balance fetch
        fetchWalletBalance();

        // Cleanup function
        return cleanup;
    }, []);

    const toggleWallet = () => {
        setScreen(5);
    };

    const fetchWalletBalance = async () => {
        try {
            const response = await fetch("https://app.streamneo.com/user/wallet-balance", {
                headers: { Authorization: localStorage.getItem("token") },
            });
            const data = await response.json();
            setWalletBalance(data.balance);
        } catch (error) {
            console.error("Error fetching wallet balance:", error);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '100vh',
                    bgcolor: '#F8FAFC'
                }}
            >
                <Header
                    onClick={setScreen}
                    activeScreen={screen}
                    onProfileClick={() => {
                        setScreen(4);
                    }}
                    toggleWallet={toggleWallet}
                />
                <Fade in={true} timeout={300}>
                    <Box>
                        {screen === 0 && <Dashboard
                            onClick={setScreen}
                            activeScreen={screen} />}
                        {screen === 1 && <SlotsScreen
                            onClick={setScreen}
                            activeScreen={screen} />}
                        {screen === 2 && <VideoUpload
                            onClick={setScreen}
                            activeScreen={screen} />}
                        {screen === 3 && <Billing
                            onClick={setScreen}
                            activeScreen={screen} />}
                        {screen === 4 && <ProfileScreen />}
                        {screen === 5 && <WalletScreen />}
                    </Box>
                </Fade>
            </Box>
        </ThemeProvider>
    );
};

export default Home;