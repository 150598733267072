import React, { useEffect, useState } from 'react';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Box,
    Fade,
    Grow,
    Paper,
    useTheme,
    alpha,
    Container
} from '@mui/material';
import {
    VideoLibrary as VideoLibraryIcon,
    Storage as StorageIcon,
    EventSeat as EventSeatIcon,
    AccountBalanceWallet as WalletIcon,
    AccessTime as TimerIcon
} from '@mui/icons-material';

const StatCard = ({ icon: Icon, title, value, color, index }) => {
    const theme = useTheme();

    return (
        <Grow
            in
            timeout={1000}
            style={{ transformOrigin: '0 0 0' }}
            {...{ timeout: 1000 + (index * 200) }}
        >
            <Card
                elevation={0}
                sx={{
                    height: '100%',
                    background: `linear-gradient(135deg, ${alpha(theme.palette[color].main, 0.05)} 0%, ${alpha(theme.palette[color].main, 0.1)} 100%)`,
                    border: `1px solid ${alpha(theme.palette[color].main, 0.1)}`,
                    borderRadius: 4,
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        transform: 'translateY(-8px)',
                        boxShadow: `0 8px 24px ${alpha(theme.palette[color].main, 0.15)}`,
                        background: `linear-gradient(135deg, ${alpha(theme.palette[color].main, 0.08)} 0%, ${alpha(theme.palette[color].main, 0.15)} 100%)`,
                    }
                }}
            >
                <CardContent sx={{ position: 'relative', p: 3 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            position: 'relative',
                            zIndex: 1
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: alpha(theme.palette[color].main, 0.1),
                                borderRadius: '50%',
                                p: 2,
                                mb: 2,
                                transition: 'all 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'rotate(10deg)',
                                    backgroundColor: alpha(theme.palette[color].main, 0.2),
                                }
                            }}
                        >
                            <Icon
                                sx={{
                                    fontSize: 40,
                                    color: theme.palette[color].main,
                                }}
                            />
                        </Box>
                        <Typography
                            variant="h6"
                            sx={{
                                mb: 1,
                                color: theme.palette.text.primary,
                                fontWeight: 500,
                                fontSize: '1.1rem',
                                letterSpacing: 0.5,
                            }}
                        >
                            {title}
                        </Typography>
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 600,
                                color: theme.palette[color].main,
                                fontSize: '2rem',
                                letterSpacing: 1,
                            }}
                        >
                            {value}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Grow>
    );
};

function Dashboard({ onClick, activeScreen }) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const theme = useTheme();
    const baseURL = 'https://app.streamneo.com';

    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const userId = localStorage.getItem('userId');
                const response = await fetch(`${baseURL}/dashboard/${userId}`, {
                    headers: {
                        'Authorization': `${localStorage.getItem("token")}`
                    }
                });
                if (!response.ok) throw new Error('Failed to fetch data');
                const result = await response.json();
                setData(result);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchDashboardData();
    }, []);

    const statsCards = [
        {
            icon: EventSeatIcon,
            title: 'Total Slots',
            value: data?.totalSlots || 0,
            color: 'info',
            screen: 1
        },
        {
            icon: EventSeatIcon,
            title: 'Active Slots',
            value: data?.activeSlots || 0,
            color: 'success',
            screen: 1
        },
        {
            icon: EventSeatIcon,
            title: 'Expired Slots',
            value: data?.expiredSlots || 0,
            color: 'error',
            screen: 1
        },
        {
            icon: TimerIcon,
            title: 'Slots Expiring Soon',
            value: data?.slotsExpiringSoon || 0,
            color: 'warning',
            screen: 1
        },
        {
            icon: VideoLibraryIcon,
            title: 'Total Videos',
            value: data?.totalVideos || 0,
            color: 'warning',
            screen: 2
        },
        {
            icon: StorageIcon,
            title: 'Total Video Size',
            value: data?.totalVideoSize || '0 MB',
            color: 'secondary',
            screen: 2
        },
        {
            icon: WalletIcon,
            title: 'Wallet Balance',
            value: data ? `₹${data.walletBalance.toFixed(2)}` : '₹0.00',
            color: 'success',
            screen: 3
        },
    ];

    if (loading) {
        return (<Container maxWidth="xl" sx={{
            py: 3,
        }}>
            <Paper
                elevation={0}
                sx={{
                    p: 3,
                    borderRadius: 3,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    backdropFilter: 'blur(10px)'
                }}>
                <Fade in timeout={1000}>
                    <Box>
                        <Typography
                            variant="h3"
                            align="start"
                            gutterBottom
                            sx={{
                                fontWeight: 700,
                                background: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                mb: 1,
                                fontFamily: 'Poppins'
                            }}
                        >
                            Your Dashboard
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            align="start"
                            gutterBottom
                            sx={{
                                mb: 4,
                                color: theme.palette.text.secondary,
                                fontSize: '1.15rem',
                                fontWeight: 500,
                                mx: 'auto',
                                fontFamily: 'Poppins'
                            }}
                        >
                            Welcome {localStorage.getItem("name").split(' ')[0]}! Here's your video streaming analytics
                        </Typography>
                    </Box>
                </Fade>
                <CircularProgress size={40} thickness={4} />
            </Paper>
        </Container>
        );
    }

    if (error) {
        return (
            <Container maxWidth="xl" sx={{
                py: 3,
            }}>
                <Paper
                    elevation={0}
                    sx={{
                        p: 3,
                        borderRadius: 3,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        backdropFilter: 'blur(10px)'
                    }}>
                    <Fade in timeout={1000}>
                        <Box>
                            <Typography
                                variant="h3"
                                align="start"
                                gutterBottom
                                sx={{
                                    fontWeight: 700,
                                    background: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    mb: 1,
                                    fontFamily: 'Poppins'
                                }}
                            >
                                Your Dashboard
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                align="start"
                                gutterBottom
                                sx={{
                                    mb: 4,
                                    color: theme.palette.text.secondary,
                                    fontSize: '1.15rem',
                                    fontWeight: 500,
                                    mx: 'auto',
                                    fontFamily: 'Poppins'
                                }}
                            >
                                Welcome {localStorage.getItem("name").split(' ')[0]}! Here's your video streaming analytics
                            </Typography>
                        </Box>
                    </Fade>
                    <Typography
                        color="error"
                        variant="h6"
                        sx={{
                            fontWeight: 500,
                            letterSpacing: 0.5
                        }}
                    >
                        {error}
                    </Typography>
                </Paper>
            </Container>
        );
    }

    return (
        <Container maxWidth="xl" sx={{
            py: 3,
        }}>
            <Paper
                elevation={0}
                sx={{
                    p: 3,
                    borderRadius: 3,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    backdropFilter: 'blur(10px)'
                }}>
                <Fade in timeout={1000}>
                    <Box>
                        <Typography
                            variant="h3"
                            align="start"
                            gutterBottom
                            sx={{
                                fontWeight: 700,
                                background: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                mb: 1,
                                fontFamily: 'Poppins'
                            }}
                        >
                            Your Dashboard
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            align="start"
                            gutterBottom
                            sx={{
                                mb: 4,
                                color: theme.palette.text.secondary,
                                fontSize: '1.15rem',
                                fontWeight: 500,
                                mx: 'auto',
                                fontFamily: 'Poppins'
                            }}
                        >
                            Welcome {localStorage.getItem("name").split(' ')[0]}! Here's your video streaming analytics
                        </Typography>

                        <Grid container spacing={3}>
                            {statsCards.map((card, index) => (
                                <Grid onClick={() => {
                                    onClick(card.screen);
                                }} item xs={12} sm={6} md={3} key={index}>
                                    <StatCard {...card} index={index} onClick={onClick} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Fade>
            </Paper>
        </Container>
    );
}

export default Dashboard;