import React, { useState, useEffect } from 'react';
import {
    ThemeProvider,
    createTheme,
    CssBaseline,
    useMediaQuery
} from '@mui/material';
import {
    Container,
    Box,
    Typography,
    Button,
    Chip,
    Divider,
    AppBar,
    Toolbar,
    IconButton,
    CircularProgress,
    Breadcrumbs,
    Link
} from '@mui/material';
import { Menu, ArrowLeft, Share2, Calendar, Tag } from 'lucide-react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Footer from './Footer';

function BlogDetailPage() {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [blog, setBlog] = useState(location.state?.blog || null);
    const [loading, setLoading] = useState(!location.state?.blog);
    const [error, setError] = useState(null);
    const [mode, setMode] = useState('light');
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const theme = createTheme({
        palette: {
            mode: mode,
            primary: {
                main: '#6a00ff',
            },
            background: {
                default: mode === 'dark' ? '#120329' : '#f4f4f4',
                paper: mode === 'dark' ? 'rgba(255,255,255,0.05)' : '#ffffff',
            },
            text: {
                primary: mode === 'dark' ? '#ffffff' : '#000000',
                secondary: mode === 'dark'
                    ? 'rgba(255,255,255,0.7)'
                    : 'rgba(0,0,0,0.7)',
            },
        },
        typography: {
            fontFamily: 'Inter, sans-serif',
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: mode === 'dark'
                            ? 'rgba(18, 3, 41, 0.9)'
                            : 'rgba(255, 255, 255, 0.9)',
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    }
                }
            }
        }
    });

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // Fetch blog data if not available in location state
    useEffect(() => {

        if (id) {
            const fetchBlog = async () => {
                try {
                    setLoading(true);
                    const response = await fetch(`https://admin.streamneo.com/api/blogs/${id}`);
                    if (!response.ok) {
                        throw new Error('Failed to fetch blog');
                    }
                    const data = await response.json();
                    setBlog(data);
                    setLoading(false);
                } catch (err) {
                    setError(err.message);
                    setLoading(false);
                }
            };

            fetchBlog();
        }
    }, [id]);

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const Header = () => {
        const [isScrolled, setIsScrolled] = useState(false);

        useEffect(() => {
            const handleScroll = () => {
                setIsScrolled(window.scrollY > 50);
            };

            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }, []);

        return (
            <AppBar
                position="sticky"
                sx={{
                    backdropFilter: isScrolled ? 'blur(10px)' : 'none',
                    boxShadow: isScrolled ? '0 4px 6px rgba(0,0,0,0.1)' : 'none',
                    transition: 'all 0.3s ease'
                }}
            >
                <Toolbar sx={{ justifyContent: 'space-between', py: 1 }}>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 800,
                            background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate('/')}
                    >
                        StreamNeo
                    </Typography>

                    <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', gap: 3 }}>
                        <Button
                            sx={{
                                color: theme.palette.text.primary,
                                '&:hover': { color: '#6a00ff' }
                            }}
                            onClick={() => navigate('/')}
                        >
                            Home
                        </Button>
                        <Button
                            sx={{
                                color: theme.palette.text.primary,
                                '&:hover': { color: '#6a00ff' }
                            }}
                            onClick={() => navigate('/blog')}
                        >
                            Blog
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => window.location.href = 'https://app.streamneo.com/login'}
                            sx={{
                                bgcolor: '#6a00ff',
                                color: 'white',
                                px: 3,
                                py: 1,
                                borderRadius: '32px',
                                fontSize: isMobile ? '0.9rem' : '1.1rem',
                                transition: 'all 0.3s ease',
                                transform: 'scale(1)',
                                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                '&:hover': {
                                    bgcolor: '#5a00e6',
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 6px 8px rgba(0,0,0,0.2)',
                                    background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                                },
                                '&:active': {
                                    transform: 'scale(0.95)',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                }
                            }}
                        >
                            Login
                        </Button>
                    </Box>

                    <IconButton
                        sx={{ display: { md: 'none' }, color: theme.palette.text.primary }}
                        onClick={() => setIsMobileMenuOpen(true)}
                    >
                        <Menu />
                    </IconButton>
                </Toolbar>
            </AppBar>
        );
    };

    // Function to safely render HTML content
    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box
                sx={{
                    minHeight: '100vh',
                    overflowX: 'hidden',
                    position: 'relative',
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary
                }}
            >
                <Header />

                <Container maxWidth="lg" sx={{ mt: 4, mb: 12 }}>
                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', my: 12 }}>
                            <CircularProgress sx={{ color: '#6a00ff' }} />
                        </Box>
                    ) : error ? (
                        <Box sx={{ textAlign: 'center', my: 12 }}>
                            <Typography variant="h6" color="error">
                                {error}
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{ mt: 2 }}
                                onClick={() => navigate('/blog')}
                            >
                                Back to Blogs
                            </Button>
                        </Box>
                    ) : blog ? (
                        <>
                            <Box sx={{ mb: 4 }}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                        underline="hover"
                                        color="inherit"
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => navigate('/')}
                                    >
                                        Home
                                    </Link>
                                    <Link
                                        underline="hover"
                                        color="inherit"
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => navigate('/blog')}
                                    >
                                        Blog
                                    </Link>
                                    <Typography color="text.primary">{blog.title}</Typography>
                                </Breadcrumbs>
                            </Box>

                            <Button
                                startIcon={<ArrowLeft size={16} />}
                                sx={{
                                    mb: 4,
                                    color: '#6a00ff',
                                    '&:hover': { bgcolor: 'rgba(106, 0, 255, 0.1)' }
                                }}
                                onClick={() => navigate('/blog')}
                            >
                                Back to all articles
                            </Button>

                            <Box
                                sx={{
                                    bgcolor: theme.palette.background.paper,
                                    borderRadius: 4,
                                    overflow: 'hidden',
                                    boxShadow: '0 6px 20px rgba(0,0,0,0.08)'
                                }}
                            >
                                <Box
                                    sx={{
                                        height: { xs: 200, md: 400 },
                                        backgroundImage: `url(https://admin.streamneo.com/${blog.thumbnail})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center'
                                    }}
                                />

                                <Box sx={{ p: { xs: 3, md: 6 } }}>
                                    <Box sx={{ display: 'flex', gap: 2, mb: 3, flexWrap: 'wrap' }}>
                                        <Chip
                                            icon={<Tag size={14} />}
                                            label={blog.category}
                                            size="small"
                                            sx={{
                                                bgcolor: 'rgba(106, 0, 255, 0.1)',
                                                color: '#6a00ff',
                                                fontWeight: 600
                                            }}
                                        />
                                        {blog.createdAt && (
                                            <Chip
                                                icon={<Calendar size={14} />}
                                                label={formatDate(blog.createdAt)}
                                                size="small"
                                                variant="outlined"
                                                sx={{
                                                    borderColor: 'rgba(106, 0, 255, 0.3)',
                                                    color: theme.palette.text.secondary
                                                }}
                                            />
                                        )}
                                    </Box>

                                    <Typography
                                        variant="h3"
                                        sx={{
                                            fontWeight: 800,
                                            mb: 3
                                        }}
                                    >
                                        {blog.title}
                                    </Typography>

                                    {blog.description && (
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                color: theme.palette.text.secondary,
                                                mb: 4,
                                                fontWeight: 500,
                                            }}
                                        >
                                            {blog.description}
                                        </Typography>
                                    )}

                                    <Divider sx={{ mb: 4 }} />
                                    <Box
                                        className="blog-content"
                                        sx={{
                                            '& img': {
                                                maxWidth: '100%',
                                                height: 'auto',
                                                borderRadius: 2,
                                                my: 4
                                            },
                                            '& h1, & h2, & h3, & h4, & h5, & h6': {
                                                color: theme.palette.text.primary,
                                                fontWeight: 700,
                                                mt: 4,
                                                mb: 2
                                            },
                                            '& p': {
                                                color: theme.palette.text.secondary,
                                                fontSize: '1.1rem',
                                                lineHeight: 1.7,
                                                mb: 3
                                            },
                                            '& a': {
                                                color: '#6a00ff',
                                                textDecoration: 'none',
                                                '&:hover': {
                                                    textDecoration: 'underline'
                                                }
                                            },
                                            '& ul, & ol': {
                                                ml: 3,
                                                mb: 3,
                                                color: theme.palette.text.secondary
                                            },
                                            '& li': {
                                                mb: 1
                                            }
                                        }}
                                        dangerouslySetInnerHTML={createMarkup(blog.content)}
                                    />

                                    <Divider sx={{ mt: 6, mb: 4 }} />

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Button
                                            startIcon={<Share2 size={16} />}
                                            variant="outlined"
                                            sx={{
                                                borderColor: 'rgba(106, 0, 255, 0.5)',
                                                color: '#6a00ff',
                                                '&:hover': {
                                                    borderColor: '#6a00ff',
                                                    bgcolor: 'rgba(106, 0, 255, 0.1)'
                                                }
                                            }}
                                        >
                                            Share this article
                                        </Button>

                                        <Button
                                            variant="contained"
                                            sx={{
                                                bgcolor: '#6a00ff',
                                                color: 'white',
                                                px: 3,
                                                py: 1,
                                                borderRadius: '32px',
                                                '&:hover': {
                                                    bgcolor: '#5a00e6',
                                                    background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                                                }
                                            }}
                                            onClick={() => window.location.href = 'https://app.streamneo.com/register'}
                                        >
                                            Start Free Trial
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <Box sx={{ textAlign: 'center', my: 12 }}>
                            <Typography variant="h6">
                                Blog not found
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{ mt: 2 }}
                                onClick={() => navigate('/blog')}
                            >
                                Back to Blogs
                            </Button>
                        </Box>
                    )}
                </Container>
                <Footer />
            </Box>
        </ThemeProvider>
    );
}

export default BlogDetailPage;