import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton
} from '@mui/material';
import {
  Send,
  Facebook,
  Twitter,
  Linkedin
} from 'lucide-react';

const Footer = () => {
  const [email, setEmail] = useState('');

  const footerLinks = [
    {
      title: 'Product',
      links: ['Features', 'Pricing', 'Tutorials', 'Integrations']
    },
    {
      title: 'Company',
      links: ['About', 'Careers', 'Press', 'Contact']
    },
    {
      title: 'Resources',
      links: ['Blog', 'Help Center', 'Community', 'Support']
    }
  ];

  return (
    <Box
      sx={{
        bgcolor: '#0a0216',
        color: 'white',
        py: 8,
        background: 'linear-gradient(135deg, #0a0216 0%, #1a1a2e 100%)',
        boxShadow: '0 -4px 15px rgba(0,0,0,0.1)'
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="stretch">
          {/* Brand Section */}
          <Grid item xs={12} md={4}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <div>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 900,
                    mb: 2,
                    background: 'linear-gradient(90deg, #6a11ff, #2575fc)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    letterSpacing: '-0.5px'
                  }}
                >
                  StreamNeo
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: 'rgba(255,255,255,0.8)',
                    mb: 3,
                    lineHeight: 1.6
                  }}
                >
                  Transforming content streaming with intelligent, 24/7 innovative solutions.
                </Typography>
              </div>

              {/* Social Icons */}
              <Box sx={{ display: 'flex', gap: 2 }}>
                <IconButton
                  sx={{
                    color: 'rgba(255,255,255,0.7)',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      color: '#2575fc',
                      transform: 'translateY(-3px)'
                    }
                  }}
                >
                  <Facebook strokeWidth={1.5} size={24} />
                </IconButton>
                <IconButton
                  sx={{
                    color: 'rgba(255,255,255,0.7)',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      color: '#2575fc',
                      transform: 'translateY(-3px)'
                    }
                  }}
                >
                  <Twitter strokeWidth={1.5} size={24} />
                </IconButton>
                <IconButton
                  sx={{
                    color: 'rgba(255,255,255,0.7)',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      color: '#2575fc',
                      transform: 'translateY(-3px)'
                    }
                  }}
                >
                  <Linkedin strokeWidth={1.5} size={24} />
                </IconButton>
              </Box>
            </Box>
          </Grid>

          {/* Footer Links */}
          {footerLinks.map((section) => (
            <Grid item xs={12} md={2} key={section.title}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 700,
                  mb: 3,
                  color: 'white',
                  position: 'relative',
                  paddingBottom: '10px',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '40px',
                    height: '2px',
                    background: 'linear-gradient(90deg, #6a11ff, #2575fc)'
                  }
                }}
              >
                {section.title}
              </Typography>
              {section.links.map((link) => (
                <Typography
                  key={link}
                  onClick={(event) => {
                    window.open('https://streamneo.com/' + link.toLowerCase(), '_blank', 'noopener,noreferrer');
                  }}
                  variant="body2"
                  sx={{
                    color: 'rgba(255,255,255,0.6)',
                    mb: 1.5,
                    cursor: 'pointer',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      color: '#2575fc',
                      transform: 'translateX(5px)'
                    }
                  }}
                >
                  {link}
                </Typography>
              ))}
            </Grid>
          ))}

          {/* Newsletter Signup */}
          <Grid item xs={12} md={4}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 700,
                mb: 3,
                color: 'white',
                position: 'relative',
                paddingBottom: '10px',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '40px',
                  height: '2px',
                  background: 'linear-gradient(90deg, #6a11ff, #2575fc)'
                }
              }}
            >
              Stay Updated
            </Typography>
            <Box sx={{ display: 'flex', mb: 2 }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Enter your email"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    bgcolor: 'rgba(255,255,255,0.1)',
                    color: 'white',
                    border: '1px solid rgba(255,255,255,0.2)',
                    borderRadius: '8px 0 0 8px',
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover': {
                      border: '1px solid rgba(255,255,255,0.5)'
                    }
                  },
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                variant="contained"
                sx={{
                  borderRadius: '0 8px 8px 0',
                  bgcolor: 'linear-gradient(90deg, #6a11ff, #2575fc)',
                  backgroundImage: 'linear-gradient(90deg, #6a11ff, #2575fc)',
                  '&:hover': {
                    opacity: 0.9,
                    backgroundImage: 'linear-gradient(90deg, #5a00ff, #1565fc)'
                  },
                  p: 2
                }}
              >
                <Send size={20} />
              </Button>
            </Box>
            <Typography
              variant="body2"
              sx={{
                color: 'rgba(255,255,255,0.5)',
                fontStyle: 'italic'
              }}
            >
              Subscribe for exclusive streaming insights and updates
            </Typography>
          </Grid>
        </Grid>

        {/* Copyright */}
        <Box
          sx={{
            mt: 6,
            pt: 3,
            borderTop: '1px solid rgba(255,255,255,0.1)',
            textAlign: 'center'
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: 'rgba(255,255,255,0.5)',
              letterSpacing: '0.5px'
            }}
          >
            © {new Date().getFullYear()} StreamNeo. All Rights Reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;