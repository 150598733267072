import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { Upload } from 'tus-js-client';
import {
    Checkbox,
    FormControlLabel,
    Grow,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Snackbar,
    Alert,
} from '@mui/material';
import { Stop, Restore } from '@mui/icons-material';
import {
    CircularProgress,
    IconButton,
    Modal,
    Backdrop,
    Fade,
    Button,
    Dialog,
    Zoom,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Grid,
    Card,
    CardMedia,
    CardContent,
    Box,
    Container,
    Paper,
    Divider,
    Chip,
    useTheme,
    Slider
} from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';
import { PlayArrow, CloudUpload, Delete, Add, Remove } from '@mui/icons-material';
import SubscriptionsRoundedIcon from '@mui/icons-material/SubscriptionsRounded';
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";

let socket = io('https://app.streamneo.com');

function Billing({ onClick, activeScreen }) {
    const theme = useTheme();

    const [newSlot, setNewSlot] = useState({
        title: "New Slot",
        quality: "720p",
        duration: "1_day",
        quantity: 1,
        durationValue: 1 // For the number of days or months
    });
    const [slots, setSlots] = useState([]);
    const [newRemoved, setNewRemoved] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState('');
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [title, setTitle] = useState("");
    const [videos, setVideos] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState("");
    const [streamKey, setStreamKey] = useState("");
    const [slotName, setSlotName] = useState("");
    const [loading, setLoading] = useState(true);
    const { error, isLoading, Razorpay } = useRazorpay();
    const [walletBalance, setWalletBalance] = useState(0);
    const [useWalletBalance, setUseWalletBalance] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');

    // Calculate estimated price based on quantity, duration, and duration value

    useEffect(() => {
        fetchSlots();
        fetchVideos();
    }, []);


    const showAlert = (message, severity = 'success') => {
        setAlertMessage(message);
        setAlertSeverity(severity);
        setAlertOpen(true);
    };

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertOpen(false);
    };

    const fetchSlots = async () => {
        try {
            const response = await fetch("https://app.streamneo.com/user/slots", {
                headers: { Authorization: localStorage.getItem("token") }
            });
            const data = await response.json();
            setSlots(data);
        } catch (error) {
            console.error("Error fetching slots:", error);
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {

        fetchWalletBalance();
    }, []);


    const fetchWalletBalance = async () => {
        try {
            const response = await fetch('https://app.streamneo.com/user/wallet-balance', {
                headers: { 'Authorization': localStorage.getItem('token') }
            });
            const data = await response.json();
            setWalletBalance(data.balance);
        } catch (error) {
            console.error('Error fetching wallet balance:', error);
        }
    };

    const fetchVideos = async () => {
        try {
            const response = await fetch('https://app.streamneo.com/user-videos', {
                headers: { 'Authorization': `${localStorage.getItem("token")}` }
            });
            if (response.ok) {
                const data = await response.json();
                setVideos(data.videos);
            } else {
                throw new Error('Failed to fetch videos');
            }
        } catch (error) {
            console.error("Error fetching videos:", error);
        }
    };

    const handleAddSlot = async () => {
        setUploading(true);
        try {
            const selectedSlots = slots.filter(slot => slot.isSelected);
            const payload = {
                newSlots: newSlot.quantity > 0 ? {
                    duration: newSlot.duration,
                    quantity: newSlot.quantity,
                    quality: newSlot.quality,
                    durationValue: newSlot.durationValue
                } : null,
                renewalSlots: selectedSlots.map(slot => ({
                    slotId: slot._id,
                    duration: slot.duration,
                    durationValue: slot.durationValue,
                    quality: slot.quality
                })),
                useWalletBalance,
            };

            const orderResponse = await fetch("https://app.streamneo.com/create-combined-order", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token"),
                },
                body: JSON.stringify(payload),
            });

            const orderData = await orderResponse.json();
            if (!orderResponse.ok) throw new Error(orderData.message);

            // Check if amount is 0 - directly process the purchase
            if (orderData.order.amount === 0) {
                // Call the verification endpoint directly without Razorpay
                const verificationResponse = await fetch("https://app.streamneo.com/verify-combined-payment", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem("token"),
                    },
                    body: JSON.stringify({
                        paymentId: 'ZERO_AMOUNT_PURCHASE',
                        orderId: orderData.order.id,
                        signature: 'ZERO_AMOUNT_SIGNATURE',
                    }),
                });

                const verificationData = await verificationResponse.json();
                if (!verificationResponse.ok) throw new Error(verificationData.message);

                const updatedSlots = slots.map(slot => ({ ...slot, isSelected: false }));
                setSlots(updatedSlots);
                setOpen(false);
                fetchSlots();
                fetchWalletBalance();
                showAlert("Purchase completed successfully!");
            } else {
                // Existing Razorpay payment flow
                const options = {
                    key: "rzp_test_IUYIAAIps3HYr2",
                    amount: orderData.order.amount,
                    currency: orderData.order.currency,
                    name: "StreamNeo",
                    description: `${payload.newSlots ? `${payload.newSlots.quantity} new stream(s)${selectedSlots.length > 0 ? ' and ' : ''}` : ''}${selectedSlots.length > 0 ? `${selectedSlots.length} renewal(s)` : ''}`,
                    order_id: orderData.order.id,
                    handler: async (response) => {
                        try {
                            const verificationResponse = await fetch("https://app.streamneo.com/verify-combined-payment", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: localStorage.getItem("token"),
                                },
                                body: JSON.stringify({
                                    paymentId: response.razorpay_payment_id,
                                    orderId: response.razorpay_order_id,
                                    signature: response.razorpay_signature,
                                }),
                            });

                            const verificationData = await verificationResponse.json();
                            if (!verificationResponse.ok) throw new Error(verificationData.message);

                            const updatedSlots = slots.map(slot => ({ ...slot, isSelected: false }));
                            setSlots(updatedSlots);
                            setOpen(false);
                            fetchSlots();
                            fetchWalletBalance();
                            showAlert("Purchase completed successfully!");

                        } catch (error) {
                            console.error("Payment verification failed:", error);
                            showAlert("Payment verification failed. Please try again.", "error");
                        }
                    },
                    prefill: {
                        name: localStorage.getItem("name"),
                        email: localStorage.getItem("email"),
                        contact: localStorage.getItem("mobile"),
                    },
                    theme: { color: "#3399cc" },
                };

                const razorpay = new Razorpay(options);
                razorpay.open();
            }
        } catch (error) {
            console.error("Error creating order or initiating payment:", error);
            showAlert("Something went wrong. Please try again.", "error");
        } finally {
            setUploading(false);
        }
    };

    const renewSlot = async (slot) => {
        try {
            const orderResponse = await fetch("https://app.streamneo.com/create-order", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token"),
                },
                body: JSON.stringify({
                    slotId: slot._id,
                    duration: slot.duration,
                }),
            });

            const orderData = await orderResponse.json();
            if (!orderResponse.ok) throw new Error(orderData.message);

            const { order, paymentId } = orderData;

            // Step 2: Configure Razorpay options
            const options = {
                key: "rzp_test_IUYIAAIps3HYr2", // Replace with your Razorpay Key ID
                amount: order.amount,
                currency: order.currency,
                name: "StreamNeo",
                description: "Purchase Stream",
                order_id: order.id,
                handler: async (response) => {
                    // Step 3: Verify the payment
                    try {
                        const verificationResponse = await fetch("https://app.streamneo.com/verify-payment", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: localStorage.getItem("token"),
                            },
                            body: JSON.stringify({
                                paymentId: response.razorpay_payment_id,
                                orderId: response.razorpay_order_id,
                                signature: response.razorpay_signature,
                            }),
                        });

                        const verificationData = await verificationResponse.json();
                        if (!verificationResponse.ok) throw new Error(verificationData.message);

                        // Close the form and refresh slots
                        setOpen(false);
                        fetchSlots();
                        showAlert("Stream purchased successfully!");
                    } catch (error) {
                        console.error("Payment verification failed:", error);
                        showAlert("Payment verification failed. Please try again.", "error");
                    }
                },
                prefill: {
                    name: localStorage.getItem("name"), // Replace with actual user data
                    email: localStorage.getItem("email"), // Replace with actual user data
                    contact: localStorage.getItem("mobile"), // Replace with actual user data
                },
                theme: {
                    color: "#3399cc",
                },
            };

            // Step 4: Open Razorpay Checkout
            const razorpay = new Razorpay(options);
            razorpay.open();
        } catch (error) {
            console.error("Error creating slot or initiating payment:", error);
            showAlert("Something went wrong. Please try again.", "error");
        }
        fetchSlots();
    }

    // Modify the calculatePrice function to include both new and selected slots
    const calculatePrice = () => {
        // Calculate price for new slots
        const newSlotsBasePrice = newSlot.duration === "1_day" ? 44 : 1200;
        const newSlotsTotal = newSlotsBasePrice * newSlot.quantity * newSlot.durationValue;

        // Calculate price for selected slots (renewals)
        const selectedSlots = slots.filter(slot => slot.isSelected);
        const renewalTotal = selectedSlots.reduce((sum, slot) => {
            const slotBasePrice = slot.duration === "1_day" ? 44 : 1200;
            return sum + (slotBasePrice * slot.durationValue);
        }, 0);
        const finalPrice = useWalletBalance
            ? Math.max(0, (newSlotsTotal + renewalTotal) - walletBalance)
            : newSlotsTotal + renewalTotal;

        return finalPrice;
    };

    const handleOpen = (videoUrl) => {
        setSelectedVideoUrl(videoUrl);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedVideoUrl("");
    };

    const handleCloseStreamDialog = () => {
        setDialogOpen(false);
        setSelectedVideo("");
        setStreamKey("");
    };

    const handleStartStream = async () => {
        if (selectedSlot.isActive) {
            // Stop stream logic
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                redirect: "follow"
            };
            await fetch(`https://server.streamneo.com/end-stream/${selectedSlot._id}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                })
                .catch(error => console.error(error));
        }
        try {
            const response = await fetch(`https://app.streamneo.com/server/start-stream/${selectedSlot._id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token")
                },
                body: JSON.stringify({ streamKey, videoId: selectedVideo, title: slotName })
            });
            const result = await response.text();

            fetchSlots();
            fetchVideos();
            handleCloseStreamDialog();
        } catch (error) {
            console.error("Error starting stream:", error);
        }
    };

    // Function to check if date is expired
    const isExpired = (expirationDate) => {
        return new Date(expirationDate) < new Date();
    };

    // Function to calculate remaining time
    const getRemainingTime = (expirationDate) => {
        const now = new Date();
        const expDate = new Date(expirationDate);
        const diffInMs = expDate - now;

        if (diffInMs <= 0) return null;

        const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
        if (days > 0) {
            return `${days}d ${hours}h ${minutes}m`;
        } else {
            return `${hours}h ${minutes}m`;
        }
    };

    // Handle slot quantity change
    const handleQuantityChange = (change) => {
        const newQuantity = Math.max(1, newSlot.quantity + change);
        setNewSlot({ ...newSlot, quantity: newQuantity });
    };

    // Handle duration value change
    const handleDurationValueChange = (change) => {
        const newDurationValue = Math.max(1, newSlot.durationValue + change);
        setNewSlot({ ...newSlot, durationValue: newDurationValue });
    };

    // Handle slot updates from SlotsTable
    const handleSlotUpdate = (updatedSlot) => {
        // If this is a selection change, clear other selections
        // if (updatedSlot.isSelected) {
        //     const updatedSlots = slots.map(slot =>
        //         slot._id === updatedSlot._id ? updatedSlot : { ...slot, isSelected: false }
        //     );
        //     setSlots(updatedSlots);
        // } else {
        const updatedSlots = slots.map(slot =>
            slot._id === updatedSlot._id ? updatedSlot : slot
        );
        setNewRemoved((newRemoved) => {
            if (!newRemoved) {
                setNewSlot({ ...newSlot, quantity: 0 });
            }
            return true;
        });
        setSlots(updatedSlots);
        // }
    };

    // Get the selected slot (if any)
    const getSelectedSlot = () => {
        return slots.find(slot => slot.isSelected);
    };

    // Check if any slot is selected
    const hasSelectedSlot = () => {
        return slots.some(slot => slot.isSelected);
    };

    // Modify the Order Summary section to display both new slots and renewals
    // Modify the OrderSummary component to include a remove option for new slots
    const OrderSummary = () => {
        const selectedSlots = slots.filter(slot => slot.isSelected);
        const hasNewSlots = newSlot.quantity > 0;
        const hasSelectedSlots = selectedSlots.length > 0;

        return (
            <Box sx={{
                backgroundColor: theme.palette.grey[50],
                p: 2,
                borderRadius: 2,
                border: 1,
                borderColor: theme.palette.grey[200],
                mb: 0
            }}>
                <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>
                    Order Summary
                </Typography>

                {/* New Slots Section with Remove Option */}
                {hasNewSlots && (
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                            <Typography variant="body2" color="text.secondary">
                                New Streams
                            </Typography>
                            {hasSelectedSlots && (
                                <Tooltip title="Remove new slots">
                                    <IconButton
                                        size="small"
                                        onClick={() => setNewSlot({ ...newSlot, quantity: 0 })}
                                        sx={{ p: 0.5 }}
                                    >
                                        <Delete fontSize="small" color="error" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                        <Box sx={{ mb: 2, pl: 1, borderLeft: 1, borderColor: 'divider' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                <Typography variant="body2" color="text.secondary" fontSize="0.8rem">
                                    Quantity:
                                </Typography>
                                <Typography variant="body2" color="text.secondary" fontSize="0.8rem">
                                    {newSlot.quantity}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                <Typography variant="body2" color="text.secondary" fontSize="0.8rem">
                                    Duration:
                                </Typography>
                                <Typography variant="body2" color="text.secondary" fontSize="0.8rem">
                                    {newSlot.durationValue} {newSlot.duration === "1_day" ? "Day" : "Month"}{newSlot.durationValue > 1 ? 's' : ''}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                <Typography variant="body2" color="text.secondary" fontSize="0.8rem">
                                    Quality:
                                </Typography>
                                <Typography variant="body2" color="text.secondary" fontSize="0.8rem">
                                    {newSlot.quality}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body2" color="text.secondary" fontSize="0.8rem">
                                    Subtotal:
                                </Typography>
                                <Typography variant="body2" color="text.secondary" fontSize="0.8rem">
                                    ₹{(newSlot.duration === "1_day" ? 44 : 1200) * newSlot.quantity * newSlot.durationValue}
                                </Typography>
                            </Box>
                        </Box>
                    </>
                )}

                {/* Add a "Add New Slots" button when only renewals are selected */}
                {!hasNewSlots && (
                    <Box sx={{ mb: 2 }}>
                        <Button
                            variant="outlined"
                            size="small"
                            startIcon={<Add />}
                            onClick={() => setNewSlot({ ...newSlot, quantity: 1 })}
                            sx={{
                                borderRadius: 1,
                                textTransform: 'none',
                                fontSize: '0.8rem'
                            }}
                        >
                            Add New Slots
                        </Button>
                    </Box>
                )}

                {/* Selected Slots Section - No changes needed here */}
                {hasSelectedSlots && (
                    <>
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                            Renewals
                        </Typography>
                        {selectedSlots.map((slot, index) => (
                            <Box key={slot._id} sx={{ mb: 2, pl: 1, borderLeft: 1, borderColor: 'divider' }}>
                                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                                    {index + 1}. {slot.title}
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                    <Typography variant="body2" color="text.secondary" fontSize="0.8rem">
                                        Duration:
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" fontSize="0.8rem">
                                        {slot.durationValue} {slot.duration === "1_day" ? "Day" : "Month"}{slot.durationValue > 1 ? 's' : ''}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                    <Typography variant="body2" color="text.secondary" fontSize="0.8rem">
                                        Quality:
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" fontSize="0.8rem">
                                        {slot.quality}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant="body2" color="text.secondary" fontSize="0.8rem">
                                        Subtotal:
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" fontSize="0.8rem">
                                        ₹{(slot.duration === "1_day" ? 44 : 1200) * slot.durationValue}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </>
                )}

                <Divider sx={{ my: 1 }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle2">
                        Total {hasNewSlots && hasSelectedSlots ? '(New + Renewals)' :
                            hasNewSlots ? '(New Streams)' : '(Renewals)'}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                        ₹{calculatePrice()}
                    </Typography>
                </Box>
            </Box>
        );
    };
    // Modify the getActionButtonText function to reflect combined purchase
    const getActionButtonText = () => {
        const selectedSlots = slots.filter(slot => slot.isSelected);
        const hasNewSlots = newSlot.quantity > 0;

        if (selectedSlots.length > 0 && hasNewSlots) {
            return `Purchase ${newSlot.quantity} New + Renew ${selectedSlots.length} Slots`;
        } else if (selectedSlots.length > 0) {
            return `Renew ${selectedSlots.length} Selected Slot${selectedSlots.length > 1 ? 's' : ''}`;
        } else {
            return `Purchase ${newSlot.quantity} New Stream${newSlot.quantity > 1 ? 's' : ''}`;
        }
    };

    if (loading) {
        return (<Container maxWidth="xl" sx={{
            py: 3,
        }}>
            <Paper
                elevation={0}
                sx={{
                    p: 3,
                    borderRadius: 3,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    backdropFilter: 'blur(10px)'
                }}>
                <Fade in timeout={1000}>
                    <Box>
                        <Typography
                            variant="h3"
                            align="start"
                            gutterBottom
                            sx={{
                                fontWeight: 700,
                                background: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                mb: 1,
                                fontFamily: 'Poppins'
                            }}
                        >
                            Your Purchases
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            align="start"
                            gutterBottom
                            sx={{
                                mb: 4,
                                color: theme.palette.text.secondary,
                                fontSize: '1.15rem',
                                fontWeight: 500,
                                mx: 'auto',
                                fontFamily: 'Poppins'
                            }}
                        >
                            {localStorage.getItem("name").split(' ')[0]}! Here you can manage your slots and wallet balance.
                        </Typography>
                    </Box>
                </Fade>
                <CircularProgress size={40} thickness={4} />
            </Paper>
        </Container>
        );
    }

    return (
        <Container maxWidth="xl" sx={{
            py: 3,
        }}>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={handleAlertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={alertSeverity}
                    sx={{ width: '100%' }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Grid container spacing={4}>
                {/* Left Side - Video Library */}
                <Grid item xs={12} md={8}>
                    <Paper
                        elevation={0}
                        sx={{
                            p: 3,
                            borderRadius: 3,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            backdropFilter: 'blur(10px)'
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                            <div style={{ flexDirection: 'column', justifyContent: 'start', alignItems: 'start', display: 'flex', alignItems: 'start' }}>
                                <Typography
                                    variant="h3"
                                    align="start"
                                    gutterBottom
                                    sx={{
                                        fontWeight: 700,
                                        background: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        mb: 1,
                                        fontFamily: 'Poppins'
                                    }}
                                >
                                    Your Purchases
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    align="start"
                                    gutterBottom
                                    sx={{
                                        mb: 4,
                                        color: theme.palette.text.secondary,
                                        fontSize: '1.15rem',
                                        fontWeight: 500,
                                        mx: 'auto',
                                        fontFamily: 'Poppins'
                                    }}
                                >
                                    {localStorage.getItem("name").split(' ')[0]}! Here you can manage your slots and wallet balance.
                                </Typography>
                            </div>
                        </Box>
                        <Grid container spacing={3}>
                            <SlotsTable
                                slots={slots}
                                onSlotUpdate={handleSlotUpdate}
                                onStreamAction={(slot, type) => {
                                    renewSlot(slot);
                                }}
                                fetchSlots={fetchSlots}
                                fetchVideos={fetchVideos}
                            />
                        </Grid>
                    </Paper>
                </Grid>

                {/* Right Side - Purchase Section */}
                <Grid item xs={12} md={4}>
                    <Grow
                        in
                        timeout={1000}
                        style={{ transformOrigin: '0 0 0' }}
                        {...{ timeout: 1000 + (0 * 200) }}
                    >
                        <Paper
                            elevation={0}
                            sx={{
                                p: 3,
                                borderRadius: 3,
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                backdropFilter: 'blur(10px)'
                            }}
                        >
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: 700,
                                    mb: 3,
                                    color: 'text.primary'
                                }}
                            >
                                {hasSelectedSlot() ? 'Extend/Renew Selected Stream' : 'Purchase Streams'}
                            </Typography>

                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ mb: 4 }}
                            >
                                {hasSelectedSlot()
                                    ? 'Extend/Renew the duration of your selected stream.'
                                    : 'Every stream is a server instance that you can manage from this dashboard.'}
                            </Typography>

                            <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>

                                <Grid container spacing={4}>
                                    <Grid item xs={6} md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel>Quality</InputLabel>
                                            <Select
                                                value={newSlot.quality}
                                                onChange={(e) => setNewSlot({ ...newSlot, quality: e.target.value })}
                                                sx={{ borderRadius: 2 }}>
                                                <MenuItem value="720p">720p</MenuItem>
                                                <MenuItem value="1080p">1080p</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel>Duration Type</InputLabel>
                                            <Select
                                                value={newSlot.duration}
                                                onChange={(e) => setNewSlot({ ...newSlot, duration: e.target.value })}
                                                sx={{ borderRadius: 2 }}
                                            >
                                                <MenuItem value="1_day">Days</MenuItem>
                                                <MenuItem value="1_month">Months</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4}>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Number of Slots
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <IconButton
                                                onClick={() => handleQuantityChange(-1)}
                                                disabled={newSlot.quantity <= 1}
                                                size="small"
                                                sx={{
                                                    border: 1,
                                                    borderColor: 'divider',
                                                    borderRadius: 1
                                                }}
                                            >
                                                <Remove fontSize="small" />
                                            </IconButton>
                                            <TextField
                                                value={newSlot.quantity}
                                                onChange={(e) => {
                                                    const value = parseInt(e.target.value) || 1;
                                                    setNewSlot({ ...newSlot, quantity: Math.max(1, value) });
                                                }}
                                                inputProps={{
                                                    min: 1,
                                                    style: { textAlign: 'center' }
                                                }}
                                                variant="outlined"
                                                size="small"
                                                sx={{ width: '70px' }}
                                            />
                                            <IconButton
                                                onClick={() => handleQuantityChange(1)}
                                                size="small"
                                                sx={{
                                                    border: 1,
                                                    borderColor: 'divider',
                                                    borderRadius: 1
                                                }}
                                            >
                                                <Add fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    </Grid>

                                    {/* Duration Value Selector */}
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Number of {newSlot.duration === "1_day" ? "Days" : "Months"}
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <IconButton
                                                onClick={() => handleDurationValueChange(-1)}
                                                disabled={newSlot.durationValue <= 1}
                                                size="small"
                                                sx={{
                                                    border: 1,
                                                    borderColor: 'divider',
                                                    borderRadius: 1
                                                }}
                                            >
                                                <Remove fontSize="small" />
                                            </IconButton>
                                            <TextField
                                                value={newSlot.durationValue}
                                                onChange={(e) => {
                                                    const value = parseInt(e.target.value) || 1;
                                                    setNewSlot({ ...newSlot, durationValue: Math.max(1, value) });
                                                }}
                                                inputProps={{
                                                    min: 1,
                                                    style: { textAlign: 'center' }
                                                }}
                                                variant="outlined"
                                                size="small"
                                                sx={{ width: '70px' }}
                                            />
                                            <IconButton
                                                onClick={() => handleDurationValueChange(1)}
                                                size="small"
                                                sx={{
                                                    border: 1,
                                                    borderColor: 'divider',
                                                    borderRadius: 1
                                                }}
                                            >
                                                <Add fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <OrderSummary />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={useWalletBalance}
                                            onChange={(e) => setUseWalletBalance(e.target.checked)}
                                            disabled={walletBalance <= 0}
                                        />
                                    }
                                    label={`Use Wallet Balance (₹${walletBalance})`}
                                />
                                <Button
                                    variant="contained"
                                    fullWidth
                                    size="large"
                                    onClick={handleAddSlot}
                                    startIcon={uploading ? null : hasSelectedSlot() ? <Restore /> : <Add />}
                                    sx={{
                                        borderRadius: 2,
                                        py: 1.5,
                                        backgroundColor: 'primary.main',
                                        '&:hover': {
                                            backgroundColor: 'primary.dark',
                                        },
                                        textTransform: 'none',
                                        fontWeight: 600
                                    }}
                                >
                                    {uploading ? <CircularProgress size={20} thickness={4} color='white' /> : getActionButtonText()}
                                </Button>
                            </Box>
                        </Paper>
                    </Grow>
                </Grid>
            </Grid >
        </Container >
    );
}

const SlotsTable = ({ slots, onSlotUpdate, onStreamAction, fetchSlots, fetchVideos }) => {
    const theme = useTheme();

    const isExpired = (expirationDate) => {
        return new Date(expirationDate) < new Date();
    };

    const getRemainingTime = (expirationDate) => {
        const now = new Date();
        const expDate = new Date(expirationDate);
        const diffInMs = expDate - now;

        if (diffInMs <= 0) return null;

        const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

        if (days > 0) {
            return `${days}d ${hours}h ${minutes}m`;
        } else {
            return `${hours}h ${minutes}m`;
        }
    };

    // Handle quality change
    const handleQualityChange = (slotId, newQuality) => {
        const slotToUpdate = slots.find(slot => slot._id === slotId);
        if (slotToUpdate) {
            const updatedSlot = { ...slotToUpdate, quality: newQuality };
            onSlotUpdate(updatedSlot);
        }
    };

    // Handle duration type change
    const handleDurationChange = (slotId, newDuration) => {
        const slotToUpdate = slots.find(slot => slot._id === slotId);
        if (slotToUpdate) {
            const updatedSlot = { ...slotToUpdate, duration: newDuration };
            onSlotUpdate(updatedSlot);
        }
    };

    // Handle duration value change
    const handleDurationValueChange = (slotId, change) => {
        const slotToUpdate = slots.find(slot => slot._id === slotId);
        if (slotToUpdate) {
            const newDurationValue = Math.max(1, parseInt(slotToUpdate.durationValue) + change);
            const updatedSlot = { ...slotToUpdate, durationValue: newDurationValue };
            onSlotUpdate(updatedSlot);
        }
    };

    // Handle slot selection (checkbox)
    const handleSlotSelection = (slotId, isSelected) => {
        const slotToUpdate = slots.find(slot => slot._id === slotId);
        if (slotToUpdate) {
            const updatedSlot = { ...slotToUpdate, isSelected };
            onSlotUpdate(updatedSlot);
        }
    };

    return (
        <Box sx={{ width: '100%', m: 2 }}>
            <Grow
                in
                timeout={1000}
                style={{ transformOrigin: '0 0 0' }}
                {...{ timeout: 1000 + (0 * 200) }}
            >
                <TableContainer
                    component={Paper}
                    elevation={0}
                    sx={{
                        borderRadius: 3,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        backdropFilter: 'blur(10px)',
                        overflow: 'hidden'
                    }}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 600 }}>Select</TableCell>
                                <TableCell sx={{ fontWeight: 600 }}>Title</TableCell>
                                <TableCell sx={{ fontWeight: 600 }}>Quality</TableCell>
                                <TableCell sx={{ fontWeight: 600 }}>Type</TableCell>
                                <TableCell sx={{ fontWeight: 600 }}>Duration</TableCell>
                                <TableCell sx={{ fontWeight: 600 }}>Time left</TableCell>
                                <TableCell sx={{ fontWeight: 600 }} align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {slots.map((slot) => {
                                const expired = isExpired(slot.expirationDate);
                                const remainingTime = getRemainingTime(slot.expirationDate);

                                return (
                                    <TableRow
                                        key={slot._id}
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                            },
                                            opacity: expired ? 0.7 : 1,
                                        }}
                                    >
                                        <TableCell>
                                            <Checkbox
                                                checked={slot.isSelected ?? false}
                                                onChange={(e) => { handleSlotSelection(slot._id, e.target.checked) }}
                                                size="small"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                                {slot.title}
                                            </Typography>
                                            <Chip
                                                label={slot.isActive ? 'Active' : 'Inactive'}
                                                color={slot.isActive ? 'success' : 'default'}
                                                size="small"
                                                sx={{ borderRadius: 1 }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <FormControl fullWidth disabled={!slot.isSelected}>
                                                <Select
                                                    value={slot.quality}
                                                    onChange={(e) => { handleQualityChange(slot._id, e.target.value) }}
                                                    sx={{ borderRadius: 2 }}
                                                >
                                                    <MenuItem value="720p">720p</MenuItem>
                                                    <MenuItem value="1080p">1080p</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell>
                                            <FormControl fullWidth disabled={!slot.isSelected}>
                                                <Select
                                                    value={slot.duration}
                                                    onChange={(e) => { handleDurationChange(slot._id, e.target.value) }}
                                                    sx={{ borderRadius: 2 }}
                                                >
                                                    <MenuItem value="1_day">Days</MenuItem>
                                                    <MenuItem value="1_month">Months</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} disabled={!slot.isSelected}>
                                                <IconButton
                                                    onClick={() => handleDurationValueChange(slot._id, -1)}
                                                    disabled={!slot.isSelected || slot.durationValue <= 1}
                                                    size="small"
                                                    sx={{
                                                        border: 1,
                                                        borderColor: 'divider',
                                                        borderRadius: 1
                                                    }}
                                                >
                                                    <Remove fontSize="small" />
                                                </IconButton>
                                                <TextField
                                                    value={`${slot.durationValue} ${slot.duration === "1_day" ? "Days" : "Months"}`}
                                                    onChange={(e) => { }}
                                                    inputProps={{
                                                        min: 1,
                                                        style: { textAlign: 'center' }
                                                    }}
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{ width: '70px' }}
                                                    disabled={!slot.isSelected}
                                                />
                                                <IconButton
                                                    onClick={() => handleDurationValueChange(slot._id, 1)}
                                                    disabled={!slot.isSelected}
                                                    size="small"
                                                    sx={{
                                                        border: 1,
                                                        borderColor: 'divider',
                                                        borderRadius: 1
                                                    }}
                                                >
                                                    <Add fontSize="small" />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Chip
                                                label={expired ? 'Expired' : remainingTime}
                                                color={expired ? 'error' : 'primary'}
                                                size="small"
                                                sx={{ borderRadius: 1 }}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            {expired ? (
                                                <Tooltip title="Renew Stream">
                                                    <IconButton
                                                        onClick={() => onStreamAction(slot, 'renew')}
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: theme.palette.grey[100],
                                                            '&:hover': {
                                                                backgroundColor: theme.palette.grey[200],
                                                            },
                                                        }}
                                                    >
                                                        <Restore fontSize="small" color="primary" /> {/* Renew Icon */}
                                                    </IconButton>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Extend Stream">
                                                    <IconButton
                                                        onClick={() => onStreamAction(slot, 'extend')}
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: theme.palette.grey[100],
                                                            '&:hover': {
                                                                backgroundColor: theme.palette.grey[200],
                                                            },
                                                        }}
                                                    >
                                                        <Add fontSize="small" color="primary" /> {/* Extend Icon */}
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grow>
        </Box>
    );
};

export default Billing;