import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { Upload } from 'tus-js-client';
import { gapi } from "gapi-script";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    Typography,
    TextField,
    Button,
    CircularProgress,
    Box,
    IconButton,
    Modal,
    Container,
    Paper,
    Divider,
    Zoom,
    Tooltip,
    LinearProgress,
    CssBaseline,
    Grow,
    Fade,
    Snackbar,
    Alert
} from '@mui/material';
import {
    PlayArrowRounded,
    CloudUploadRounded,
    DeleteRounded,
    EditRounded,
    VideoLibraryRounded,
    Add
} from '@mui/icons-material';

// Create custom theme with updated typography
const theme = createTheme({
    typography: {
        fontFamily: [
            'Inter',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),
        h4: {
            fontWeight: 700,
            letterSpacing: '-0.01em',
        },
        h5: {
            fontWeight: 600,
            letterSpacing: '-0.01em',
        },
        h6: {
            fontWeight: 600,
            letterSpacing: '-0.01em',
        },
        subtitle1: {
            fontWeight: 500,
            letterSpacing: '0.01em',
        },
        body1: {
            letterSpacing: '0.01em',
        },
        button: {
            fontWeight: 600,
            letterSpacing: '0.02em',
            textTransform: 'none',
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& label': {
                        fontWeight: 500,
                    },
                    '& input': {
                        fontWeight: 500,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    padding: '10px 20px',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 12,
                },
            },
        },
    },
});

let socket = io('https://app.streamneo.com');

function VideoUpload({ onClick, activeScreen }) {
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [title, setTitle] = useState("");
    const [videos, setVideos] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
    const [open2, setOpen2] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [updatedTitle, setUpdatedTitle] = useState("");
    const [loading, setLoading] = useState(true);
    const [uploadPanel, setUploadPanel] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");

    useEffect(() => {
        socket.on('upload-progress', (data) => {
            setProgress(data.progress);
        });

        fetchVideos();

        return () => {
            socket.off('upload-progress');
        };
    }, []);


    // Helper function to show alerts
    const showAlert = (message, severity = "success") => {
        setAlertMessage(message);
        setAlertSeverity(severity);
        setAlertOpen(true);
    };

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertOpen(false);
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type.startsWith('video/')) {
            setFile(selectedFile);
            setTitle(selectedFile.name.replace(/\.[^/.]+$/, ''));
        } else {
            showAlert("Please select a valid video file.", "error");
            setFile(null);
        }
        setProgress(0);
    };

    const handleUpdate = async () => {
        if (!updatedTitle.trim()) {
            showAlert("Title cannot be empty!", "error");
            return;
        }

        try {
            const response = await fetch(`https://app.streamneo.com/video/${selectedVideo._id}`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ title: updatedTitle }),
            });

            if (response.ok) {
                const updatedVideo = await response.json();
                setVideos((prevVideos) =>
                    prevVideos.map((video) =>
                        video._id === updatedVideo.video._id ? { ...video, title: updatedTitle } : video
                    )
                );
                showAlert("Video title updated successfully!");
                handleClose2();
            } else {
                const errorData = await response.json();
                showAlert(`Failed to update video title: ${errorData.error || "Unknown error"}`, "error");
            }
        } catch (error) {
            console.error("Error updating video title:", error);
            showAlert("An error occurred while updating the video title.", "error");
        }
    };

    const uploadVideo = async () => {
        if (!file) {
            showAlert("Please select a file to upload.", "error");
            return;
        }

        setUploading(true);
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        const response = await fetch("https://app.streamneo.com/server/least-active", requestOptions);
        const result = await response.json();
        socket.disconnect();
        socket = io(`http://${result.ipv4}`);
        socket.on('connect', () => {
            console.log('Connected to the dynamic server:', result.ipv4);
        });
        const upload = new Upload(file, {
            endpoint: `http://${result.ipv4}/files`,
            chunkSize: 5 * 1024 * 1024,
            retryDelays: [0, 1000, 3000, 5000],
            metadata: {
                filename: file.name,
                title,
                userId: localStorage.getItem("userId")
            },
            onError: (error) => {
                showAlert('Upload failed, please try again.', "error");
                setUploading(false);
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                const progress = (bytesUploaded / bytesTotal) * 100;
                setProgress(progress);
                socket.emit('upload-progress', { progress });
            },
            onSuccess: () => {
                showAlert('Upload completed!');
                setFile(null);
                setTitle("");
                setProgress(0);
                setUploading(false);
                fetchVideos();
            }
        });

        upload.start();
    };

    const handleDelete = async (video) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this video?");
        if (confirmDelete) {
            try {
                const response = await fetch(`https://app.streamneo.com/video/${video._id}`, {
                    method: 'DELETE',
                });

                if (response.ok) {
                    setVideos((prevVideos) => prevVideos.filter(v => v._id !== video._id));
                    showAlert("Video deleted successfully!");
                } else {
                    const errorData = await response.json();
                    showAlert(`Failed to delete video: ${errorData.error || "Unknown error"}`, "error");
                }
            } catch (error) {
                console.error("Error deleting video:", error);
                showAlert("An error occurred while deleting the video. Please try again.", "error");
            }
        }
    };

    const handleOpen2 = (video) => {
        setSelectedVideo(video);
        setUpdatedTitle(video.title);
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
        setSelectedVideo(null);
        setUpdatedTitle("");
    };

    const fetchVideos = async () => {
        try {
            const response = await fetch('https://app.streamneo.com/user-videos', {
                headers: { 'Authorization': `${localStorage.getItem("token")}` }
            });
            if (response.ok) {
                const data = await response.json();
                setVideos(data.videos);
            } else {
                throw new Error('Failed to fetch videos');
            }
        } catch (error) {
            console.error("Error fetching videos:", error);
        }
        finally {
            setLoading(false);
        }
    };

    const handleOpen = (videoUrl) => {
        setSelectedVideoUrl(videoUrl);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedVideoUrl("");
    };

    const handleEdit = (video) => {
        // Your edit logic here
        handleOpen2(video);
    };

    useEffect(() => {
        // Dynamically load the Google API script
        const script = document.createElement("script");
        script.src = "https://apis.google.com/js/api.js";
        script.onload = () => {
            console.log("Google API loaded");
            // gapi.load("client:auth2", initGooglePicker);
        };
        document.body.appendChild(script);
    }, []);

    const initGooglePicker = () => {
        gapi.load("auth", { callback: onAuthApiLoad });
        gapi.load("picker", { callback: onPickerApiLoad });
    };

    const handleAuthResult = (authResult) => {
        if (authResult && !authResult.error) {
            console.log("Google Drive authenticated successfully");
        } else {
            console.error("Google Drive authentication failed");
        }
    };

    const onAuthApiLoad = () => {
        gapi.auth.authorize(
            {
                client_id: "409218539200-psjqm203lg8a0fsi50hept44r169ml72.apps.googleusercontent.com",
                scope: ["https://www.googleapis.com/auth/drive.file"],
                immediate: true,
            },
            handleAuthResult
        );
    };

    const onPickerApiLoad = () => {
        if (gapi.auth && gapi.auth.getToken()) {
            const picker = new window.google.picker.PickerBuilder()
                .addView(window.google.picker.ViewId.DOCS)
                .setOAuthToken(gapi.auth.getToken().access_token)
                .setDeveloperKey("AIzaSyA9l-HNGWb7HkzTYQgrRw4v5myPkDswH7Q")
                .setCallback(pickerCallback)
                .build();
            picker.setVisible(true);
        }
    };

    const pickerCallback = (data) => {
        if (data.action === window.google.picker.Action.PICKED) {
            const fileId = data.docs[0].id;
            fetchVideoFromDrive(fileId);
        }
    };

    const fetchVideoFromDrive = async (fileId) => {
        try {
            const response = await fetch(
                `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`,
                {
                    headers: {
                        Authorization: `Bearer ${gapi.auth.getToken().access_token}`,
                    },
                }
            );
            const videoBlob = await response.blob();
            const file = new File([videoBlob], "DriveVideo.mp4", { type: videoBlob.type });
            // Handle the video upload as usual
            uploadVideo(file);
        } catch (error) {
            console.error("Error fetching video from Google Drive:", error);
        }
    };


    if (loading) {
        return (<Container maxWidth="xl" sx={{
            py: 3,
        }}>
            <Paper
                elevation={0}
                sx={{
                    p: 3,
                    borderRadius: 3,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    backdropFilter: 'blur(10px)'
                }}>
                <Fade in timeout={1000}>
                    <Box>
                        <Typography
                            variant="h3"
                            align="start"
                            gutterBottom
                            sx={{
                                fontWeight: 700,
                                background: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                mb: 1,
                                fontFamily: 'Poppins'
                            }}
                        >
                            Your Videos
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            align="start"
                            gutterBottom
                            sx={{
                                mb: 4,
                                color: theme.palette.text.secondary,
                                fontSize: '1.15rem',
                                fontWeight: 500,
                                mx: 'auto',
                                fontFamily: 'Poppins'
                            }}
                        >
                            {localStorage.getItem("name").split(' ')[0]}! Here are all your videos available for streaming.
                        </Typography>
                    </Box>
                </Fade>
                <CircularProgress size={40} thickness={4} />
            </Paper>
        </Container>
        );
    }

    return (
        <Container maxWidth="xl" sx={{
            py: 3,
        }}>
            <Grid container spacing={4}>
                {/* Left Side - Video Library */}
                <Grid item xs={12} md={uploadPanel ? 9 : 12}>
                    <Paper
                        elevation={0}
                        sx={{
                            p: 3,
                            borderRadius: 3,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            backdropFilter: 'blur(10px)'
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                            <div style={{ flexDirection: 'column', justifyContent: 'start', display: 'flex', alignItems: 'start' }}>
                                <Typography
                                    variant="h3"
                                    align="start"
                                    gutterBottom
                                    sx={{
                                        fontWeight: 700,
                                        background: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        mb: 1,
                                        fontFamily: 'Poppins'
                                    }}
                                >
                                    Your Videos
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    align="start"
                                    gutterBottom
                                    sx={{
                                        mb: 4,
                                        color: theme.palette.text.secondary,
                                        fontSize: '1.15rem',
                                        fontWeight: 500,
                                        mx: 'auto',
                                        fontFamily: 'Poppins'
                                    }}
                                >
                                    {localStorage.getItem("name").split(' ')[0]}! Here are all your videos available for streaming.
                                </Typography>
                            </div>
                            <div style={{ flexDirection: 'row', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                {/* <StatusChip activeStreams={activeStreams} totalSlots={totalSlots} /> */}
                                <Button
                                    disabled={uploading}
                                    onClick={() => {
                                        // onClick(3);
                                        setUploadPanel(!uploadPanel);
                                    }}
                                    variant="outlined"
                                    sx={{
                                        borderRadius: 2,
                                        textTransform: 'none'
                                    }}
                                >
                                    {uploadPanel ? 'Cancel Upload' : 'Upload Video'}
                                </Button>

                            </div>
                        </Box>
                        <Grid container spacing={3}>
                            {videos.map((video, index) => (
                                <Grid item xs={12} sm={6} md={uploadPanel ? 4 : 3} key={video._id}>
                                    <Grow
                                        in
                                        timeout={1000}
                                        style={{ transformOrigin: '0 0 0' }}
                                        {...{ timeout: 1000 + (index * 200) }}
                                    >
                                        <Card
                                            sx={{
                                                borderRadius: 3,
                                                boxShadow: '0 8px 24px rgba(0,0,0,0.08)',
                                                transition: 'all 0.3s ease',
                                                '&:hover': {
                                                    transform: 'translateY(-4px)',
                                                    boxShadow: '0 12px 32px rgba(0,0,0,0.12)',
                                                },
                                                position: 'relative',
                                                height: 240,
                                                overflow: 'hidden',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <CardMedia
                                                component="img"
                                                image={`https://server.streamneo.com/${video.thumbnailPath.replace("/var/www/html/", "")}`}
                                                alt={video.title}
                                                sx={{
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                }}
                                            />

                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: 16,
                                                    right: 16,
                                                    display: 'flex',
                                                    gap: 1,
                                                    zIndex: 1,
                                                }}
                                            >
                                                <Tooltip title="Play Video" arrow>
                                                    <IconButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleOpen(`https://server.streamneo.com/${video.uploadPath.replace("/var/www/html/", "")}.mp4`);
                                                        }}
                                                        sx={{
                                                            backgroundColor: 'rgba(255,255,255,0.9)',
                                                            '&:hover': {
                                                                backgroundColor: 'white',
                                                            },
                                                        }}
                                                    >
                                                        <PlayArrowRounded fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Edit Title" arrow>
                                                    <IconButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleEdit(video);
                                                        }}
                                                        sx={{
                                                            backgroundColor: 'rgba(255,255,255,0.9)',
                                                            '&:hover': {
                                                                backgroundColor: 'white',
                                                            },
                                                        }}
                                                    >
                                                        <EditRounded fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete Video" arrow>
                                                    <IconButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleDelete(video);
                                                        }}
                                                        sx={{
                                                            backgroundColor: 'rgba(255,255,255,0.9)',
                                                            '&:hover': {
                                                                backgroundColor: 'white',
                                                            },

                                                        }}
                                                    >
                                                        <DeleteRounded fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>

                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    left: 0,
                                                    right: 0,
                                                    background: 'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%)',
                                                    p: 2,
                                                }}
                                            >
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{
                                                        color: 'white',
                                                        fontWeight: 600,
                                                        textShadow: '0 2px 4px rgba(0,0,0,0.2)',
                                                    }}
                                                >
                                                    {video.title}
                                                </Typography>
                                            </Box>
                                        </Card>
                                    </Grow>
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
                </Grid>

                {/* Upload Section */}
                {uploadPanel ? <Grid item xs={12} md={3}>
                    <Grow
                        in
                        timeout={1000}
                        style={{ transformOrigin: '0 0 0' }}
                        {...{ timeout: 1000 + (0 * 200) }}
                    >
                        <Paper
                            elevation={0}
                            sx={{
                                p: 3,
                                borderRadius: 3,
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                backdropFilter: 'blur(10px)'
                            }}
                        >
                            <Typography variant="h5" gutterBottom>
                                Upload Video
                            </Typography>
                            <Divider sx={{ my: 2 }} />

                            <Box
                                sx={{
                                    border: '2px dashed',
                                    borderColor: 'primary.main',
                                    borderRadius: 2,
                                    p: 3,
                                    mb: 3,
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        bgcolor: 'action.hover',
                                    }
                                }}
                            >
                                <input
                                    type="file"
                                    accept="video/*"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    id="video-upload"
                                />
                                <label htmlFor="video-upload">
                                    <CloudUploadRounded
                                        sx={{
                                            fontSize: 48,
                                            color: 'primary.main',
                                            mb: 1.5
                                        }}
                                    />
                                    <Typography
                                        variant="body1"
                                        color="text.secondary"
                                        sx={{ fontWeight: 500 }}
                                    >
                                        Drag and drop or click to upload
                                    </Typography>
                                </label>
                            </Box>

                            {file && (
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{ mb: 2, fontWeight: 500 }}
                                >
                                    Selected: {file.name}
                                </Typography>
                            )}

                            <TextField
                                label="Video Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                fullWidth
                                variant="outlined"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: 2
                                    },
                                    mb: 3
                                }}
                            />
                            <Button
                                variant="contained"
                                fullWidth
                                size="large"
                                onClick={uploadVideo}
                                startIcon={uploading ? null : <Add />}
                                sx={{
                                    borderRadius: 2,
                                    py: 1.5,
                                    backgroundColor: 'primary.main',
                                    '&:hover': {
                                        backgroundColor: 'primary.dark',
                                    },
                                    textTransform: 'none',
                                    fontWeight: 600,
                                    mb: 3
                                }}
                            >
                                {uploading ? <CircularProgress size={20} thickness={4} color='white' /> : 'Upload Video'}
                            </Button>

                            <Button
                                variant="contained"
                                fullWidth
                                size="large"
                                onClick={initGooglePicker}
                                startIcon={<Add />}
                                sx={{
                                    borderRadius: 2,
                                    py: 1.5,
                                    backgroundColor: "primary.main",
                                    "&:hover": {
                                        backgroundColor: "primary.dark",
                                    },
                                    textTransform: "none",
                                    fontWeight: 600,
                                    mb: 3
                                }}
                            >
                                Upload from Google Drive
                            </Button>

                            {uploading && (
                                <Box sx={{ mt: 3 }}>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        align="center"
                                        sx={{ fontWeight: 500 }}
                                    >
                                        {progress.toFixed(0)}% Complete
                                    </Typography>
                                    <LinearProgress
                                        variant="determinate"
                                        value={progress}
                                        sx={{
                                            mt: 1,
                                            height: 6,
                                            borderRadius: 3
                                        }}
                                    />
                                </Box>
                            )}
                        </Paper>
                    </Grow>
                </Grid> : null}
            </Grid>

            {/* Modals with updated typography */}
            <Modal
                open={open}
                onClose={handleClose}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Paper
                    sx={{
                        width: '90%',
                        maxWidth: 1000,
                        p: 3,
                        borderRadius: 2,
                        outline: 'none'
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        Video Preview
                    </Typography>
                    <video
                        controls
                        autoPlay
                        src={selectedVideoUrl}
                        style={{
                            width: '100%',
                            borderRadius: '8px'
                        }}
                    />
                </Paper>
            </Modal>

            <Modal
                open={open2}
                onClose={handleClose2}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Paper
                    sx={{
                        width: '90%',
                        maxWidth: 400,
                        p: 3,
                        borderRadius: 2,
                        outline: 'none'
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        Edit Video Title
                    </Typography>
                    <TextField
                        fullWidth
                        label="Title"
                        value={updatedTitle}
                        onChange={(e) => setUpdatedTitle(e.target.value)}
                        sx={{ mb: 3 }}
                    />
                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                        <Button variant="outlined" onClick={handleClose2}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={handleUpdate}>
                            Save Changes
                        </Button>
                    </Box>
                </Paper>
            </Modal>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={handleAlertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={alertSeverity}
                    sx={{ width: '100%' }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Container >
    );
}

export default VideoUpload;