import React, { useState } from 'react';
import {
    TextField,
    Button,
    Container,
    Typography,
    Box,
    Paper,
    Alert,
    Snackbar,
    InputAdornment
} from '@mui/material';
import { Mail } from 'lucide-react';
import axios from 'axios';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');
        setMessage('');

        try {
            const response = await axios.post('https://app.streamneo.com/forgot-password', { email });
            setMessage(response.data.message);
            setOpenSnackbar(true);
        } catch (err) {
            setError(err.response?.data?.error || 'An error occurred');
            setOpenSnackbar(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <Container
            maxWidth={false}
            disableGutters
            sx={{
                minHeight: '100vh',
                background: 'linear-gradient(145deg, #f6f8ff 0%, #f0f3ff 100%)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: { xs: 2, sm: 4 },
                fontFamily: 'Poppins, sans-serif',
            }}
        >
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={message ? 'success' : 'error'}
                    sx={{ width: '100%' }}
                >
                    {message || error}
                </Alert>
            </Snackbar>

            <Paper
                elevation={0}
                sx={{
                    width: '100%',
                    maxWidth: '500px',
                    p: { xs: 4, md: 6 },
                    borderRadius: '24px',
                    boxShadow: '0 8px 40px rgba(0,0,0,0.08)',
                    bgcolor: '#ffffff',
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        color: '#6a00ff',
                        fontWeight: 700,
                        mb: 1,
                        textAlign: 'center'
                    }}
                >
                    StreamNeo
                </Typography>
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: 700,
                        mb: 2,
                        textAlign: 'center'
                    }}
                >
                    Forgot Password
                </Typography>
                <Typography
                    sx={{
                        color: 'text.secondary',
                        mb: 4,
                        textAlign: 'center'
                    }}
                >
                    Enter your email to receive a password reset link
                </Typography>

                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        type="email"
                        margin="normal"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Mail size={20} color="#666" />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            mb: 3,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '12px',
                            }
                        }}
                    />

                    <Button
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        disabled={isLoading}
                        sx={{
                            bgcolor: '#6a00ff',
                            borderRadius: '12px',
                            py: 1.5,
                            textTransform: 'none',
                            fontSize: '16px',
                            '&:hover': {
                                bgcolor: '#5a00e6',
                            },
                            '&.Mui-disabled': {
                                bgcolor: 'gray',
                                opacity: 0.6,
                            }
                        }}
                    >
                        {isLoading ? "Sending..." : "Send Reset Link"}
                    </Button>

                    <Typography
                        align="center"
                        sx={{
                            mt: 4,
                            color: 'text.secondary'
                        }}
                    >
                        Remember your password?{' '}
                        <Typography
                            component="a"
                            href="/login"
                            sx={{
                                color: '#6a00ff',
                                textDecoration: 'none',
                                fontWeight: 600,
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            Back to Login
                        </Typography>
                    </Typography>
                </form>
            </Paper>
        </Container>
    );
}

export default ForgotPassword;