import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box, Paper, Alert, Snackbar } from '@mui/material';
import { Mail, ArrowLeft } from 'lucide-react';
import { verifyOTP } from '../api';

function EmailVerification() {
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [timeLeft, setTimeLeft] = useState(300); // 5 minutes in seconds
    const [isLoading, setIsLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setInterval(() => {
                setTimeLeft(prev => prev - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [timeLeft]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const handleOtpChange = (index, value) => {
        if (value.length > 1) return; // Prevent multiple characters

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        // Auto-focus next input
        if (value && index < 5) {
            const nextInput = document.getElementById(`otp-${index + 1}`);
            if (nextInput) nextInput.focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            const prevInput = document.getElementById(`otp-${index - 1}`);
            if (prevInput) {
                prevInput.focus();
                const newOtp = [...otp];
                newOtp[index - 1] = '';
                setOtp(newOtp);
            }
        }
    };

    const handleResendOtp = () => {
        setTimeLeft(300);
        // Add your resend OTP logic here
        setAlertMessage('OTP has been resent');
        setAlertSeverity('info');
        setAlertOpen(true);
    };

    const handleVerify = async () => {
        setIsLoading(true);
        try {
            const otpString = otp.join('');
            const response = await verifyOTP({ otp: otpString, email: localStorage.getItem("email") });
            setAlertMessage('Email verified successfully!');
            setAlertSeverity('success');
            setAlertOpen(true);

            // Redirect after a short delay to show the success message
            setTimeout(() => {
                window.location.href = '/login';
            }, 1500);
        } catch (error) {
            setAlertMessage('Verification failed. Please try again.');
            setAlertSeverity('error');
            setAlertOpen(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertOpen(false);
    };

    return (
        <Container
            maxWidth={false}
            disableGutters
            sx={{
                minHeight: '100vh',
                background: 'linear-gradient(145deg, #f6f8ff 0%, #f0f3ff 100%)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: { xs: 2, sm: 4 }
            }}
        >
            <Snackbar
                open={alertOpen}
                autoHideDuration={3000}
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseAlert}
                    severity={alertSeverity}
                    sx={{ width: '100%' }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>

            <Paper
                elevation={0}
                sx={{
                    width: '100%',
                    maxWidth: '1200px',
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    borderRadius: '24px',
                    overflow: 'hidden',
                    boxShadow: '0 8px 40px rgba(0,0,0,0.08)'
                }}
            >
                {/* Left Section - Branding */}
                <Box
                    sx={{
                        flex: 1,
                        p: 6,
                        background: 'linear-gradient(135deg, #6a00ff 0%, #8f00ff 100%)',
                        color: 'white',
                        display: { xs: 'none', md: 'flex' }, // Hide on small screens
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box>
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 700,
                                mb: 2,
                                fontSize: { xs: '24px', md: '32px' }
                            }}
                        >
                            Email Verification
                        </Typography>
                        <Typography
                            sx={{
                                mb: 4,
                                opacity: 0.8,
                                lineHeight: 1.8
                            }}
                        >
                            We've sent a verification code to your email address.
                            Please enter the code to verify your account and get started.
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: 'rgba(255,255,255,0.1)',
                            p: 4,
                            borderRadius: '16px',
                            backdropFilter: 'blur(10px)'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '18px',
                                mb: 2,
                                lineHeight: 1.6
                            }}
                        >
                            Security Tip
                        </Typography>
                        <Typography sx={{ opacity: 0.8 }}>
                            Never share your verification code with anyone. Our team will never ask for your code.
                        </Typography>
                    </Box>
                </Box>

                {/* Right Section - Verification Form */}
                <Box
                    sx={{
                        flex: 1,
                        p: { xs: 4, md: 6 },
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        bgcolor: '#ffffff'
                    }}
                >
                    <Box sx={{ maxWidth: '400px', mx: 'auto', width: '100%' }}>
                        <Button
                            startIcon={<ArrowLeft size={20} />}
                            href="/register"
                            sx={{
                                color: 'text.secondary',
                                mb: 4,
                                '&:hover': {
                                    backgroundColor: 'rgba(0,0,0,0.04)'
                                }
                            }}
                        >
                            Back to Register
                        </Button>

                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 700,
                                mb: 1
                            }}
                        >
                            Verify Your Email
                        </Typography>
                        <Typography
                            sx={{
                                color: 'text.secondary',
                                mb: 4,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1
                            }}
                        >
                            <Mail size={20} />
                            {localStorage.getItem("email")}
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1,
                                mb: 4
                            }}
                        >
                            {otp.map((digit, index) => (
                                <TextField
                                    key={index}
                                    id={`otp-${index}`}
                                    value={digit}
                                    onChange={(e) => handleOtpChange(index, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                    variant="outlined"
                                    inputProps={{
                                        maxLength: 1,
                                        style: {
                                            textAlign: 'center',
                                            fontSize: '24px',
                                            padding: '12px'
                                        }
                                    }}
                                    sx={{
                                        width: '52px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '12px',
                                        }
                                    }}
                                />
                            ))}
                        </Box>

                        <Button
                            fullWidth
                            size="large"
                            variant="contained"
                            onClick={handleVerify}
                            disabled={isLoading || otp.some(digit => !digit)}
                            sx={{
                                bgcolor: '#6a00ff',
                                borderRadius: '12px',
                                py: 1.5,
                                textTransform: 'none',
                                fontSize: '16px',
                                mb: 3,
                                '&:hover': {
                                    bgcolor: '#5a00e6',
                                },
                                '&.Mui-disabled': {
                                    bgcolor: isLoading || otp.some(digit => !digit) ? 'gray' : '#6a00ff',
                                    opacity: 0.6,
                                }
                            }}
                        >
                            {isLoading ? "Verifying..." : "Verify Email"}
                        </Button>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 1
                            }}
                        >
                            <Typography sx={{ color: 'text.secondary' }}>
                                Didn't receive code?
                            </Typography>
                            {timeLeft > 0 ? (
                                <Typography sx={{ color: '#6a00ff' }}>
                                    {formatTime(timeLeft)}
                                </Typography>
                            ) : (
                                <Button
                                    onClick={handleResendOtp}
                                    sx={{
                                        color: '#6a00ff',
                                        textTransform: 'none',
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                            textDecoration: 'underline'
                                        }
                                    }}
                                >
                                    Resend Code
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Container>
    );
}

export default EmailVerification;