import React, { useState, useEffect, useRef } from 'react';
import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  useMediaQuery
} from '@mui/material';
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Divider,
  Avatar,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Switch
} from '@mui/material';
import {
  ChevronDown,
  Check,
  Video,
  ArrowRight,
  Menu,
  Star,
  Globe,
  Music,
  Clock,
  Lock,
  BarChart,
  Send,
  Twitter,
  Linkedin,
  Sun,
  Moon
} from 'lucide-react';
import { motion } from 'framer-motion';
import Footer from './Footer';


const extendedFeatures = [
  {
    icon: <Video size={48} color="#6a00ff" />,
    title: '24/7 Live Streaming of Pre-Recorded Content',
    description: 'Continuously broadcast pre-recorded videos to platforms like YouTube, ensuring your audience has constant access to your content without the need for live recording.'
  },
  {
    icon: <Menu size={48} color="#6a00ff" />,
    title: 'User-Friendly Stream Builder',
    description: 'Utilize an intuitive, drag-and-drop interface to design and customize your live streams effortlessly, accommodating users with varying levels of technical expertise.'
  },
  {
    icon: <Globe size={48} color="#6a00ff" />,
    title: 'Multi-Platform Streaming',
    description: 'Simultaneously stream your content to multiple platforms such as YouTube, Twitch, Facebook, and more, expanding your reach across different audiences.'
  },
  {
    icon: <Music size={48} color="#6a00ff" />,
    title: 'Customizable Audio Integration',
    description: 'Enhance your streams by adding custom music, sound effects, and multi-channel audio, allowing for a personalized and engaging viewer experience.'
  },
  {
    icon: <Clock size={48} color="#6a00ff" />,
    title: 'Scheduling and Automation',
    description: 'Plan and automate your live streams to start and stop at specific times, ensuring consistent content delivery without manual intervention.'
  },
  {
    icon: <BarChart size={48} color="#6a00ff" />,
    title: 'Monetization Support with Ad Integration',
    description: 'Manage ad placements within your live streams to maximize revenue, with tools designed to optimize ad timing and viewer engagement.'
  },
  {
    icon: <Lock size={48} color="#6a00ff" />,
    title: 'Real-Time Stream Updates',
    description: 'Update your stream\'s content, including background images, videos, and audio, without interrupting the live broadcast, maintaining a seamless experience for your audience.'
  },
  {
    icon: <Lock size={48} color="#6a00ff" />,
    title: 'Secure and Compliant Platform',
    description: 'Ensure data security and privacy with a platform that adheres to GDPR compliance and offers reliable, 24/7 server availability for uninterrupted streaming.'
  }
];
// Partner Logos
const partnerLogos = [
  '/api/placeholder/120/50',
];

// Pricing Plans
const pricingPlans = [
  {
    name: '1 Day',
    price: 44,
    currency: 'INR',
    features: [
      '1 Stream',
      '24/7 Stream Duration',
      '65GB Storage',
      'Stream Designer',
      'Video Quality: 720p',
      'Audio Quality: 320kbps',
      'Instant Server Availability'
    ],
    recommended: false,
    savings: null
  },
  {
    name: '30 Days (720p)',
    price: 1199,
    currency: 'INR',
    features: [
      '2 Streams',
      '24/7 Stream Duration',
      '130GB Storage',
      'Stream Designer',
      'Video Quality: 720p',
      'Audio Quality: 320kbps',
      'Instant Server Availability'
    ],
    recommended: true,
    savings: null
  },
  {
    name: '30 Days (1080p)',
    price: 1499,
    currency: 'INR',
    features: [
      '4 Streams',
      '24/7 Stream Duration',
      '260GB Storage',
      'Stream Designer',
      'Video Quality: 1080p@30fps',
      'Audio Quality: 320kbps',
      'Instant Server Availability'
    ],
    recommended: false,
    savings: null
  }
];


// Livestream Tutorial Videos
const livestreamTutorialVideos = [
  {
    title: 'How to Stream 24/7 Tutorial',
    videoId: 'dQw4w9WgXcQ'
  }
];

const sampleTutorialVideos = [
  {
    title: 'Example 24/7 Live Stream',
    videoId: 'jfKfPfyJRdk'
  }
];

// Performance Stats
const performanceStats = [
  {
    value: '840k',
    label: 'Views',
    increase: '210%'
  },
  {
    value: '₹500+',
    label: 'Profit',
    increase: '694%'
  },
  {
    value: '2000',
    label: 'New Followers',
    increase: '500%'
  },
  {
    value: '96k+',
    label: 'Watch Time',
    increase: '400%'
  }
];

// Testimonials
const testimonials = [
  {
    name: 'Zeilhan',
    quote: 'I run a small YouTube channel with less than 100 subscribers but just using the free trial of StreamNeo got me 30 views (more than I normally get) and as subscriber IN ONE DAY.',
    rating: 5
  },
  {
    name: 'Anthony',
    quote: 'I\'m a new customer to StreamNeo, but have been using vps for the past year. I\'ve had numerous problems with my previous provider and decided to change.',
    rating: 5
  },
  {
    name: 'Marc Ryan',
    quote: 'Results speak for themselves. Since going live with StreamNeo my stream got 10k views of an account with 900 followers.',
    rating: 5
  },
  {
    name: 'Heidy Cardens',
    quote: 'When I decided to run a Stream first I needed to how to do it, and I learned. Then I had to choose a trustworthy platform.',
    rating: 5
  }
];

const faqs = [
  {
    question: 'What is StreamNeo?',
    answer: 'StreamNeo is a platform that allows you to create 24/7 live streams on YouTube using pre-recorded videos, helping you grow your channel and maintain consistent content.'
  },
  {
    question: 'How does 24/7 streaming work?',
    answer: 'Our platform lets you upload a playlist of videos that will be automatically cycled through, creating a continuous live stream that runs 24 hours a day, 7 days a week.'
  },
  {
    question: 'Is this against YouTube\'s terms of service?',
    answer: 'When used correctly and with original content, 24/7 streaming is compliant with YouTube\'s guidelines. We provide tools to help you create engaging, original content streams.'
  },
  {
    question: 'What pricing plans do you offer?',
    answer: 'We offer multiple plans from Lite to Plus, with varying numbers of streams, storage, and features. We also have a custom Pro plan for larger needs.'
  },
  {
    question: 'Can I customize my stream?',
    answer: 'Yes! Our Stream Designer allows you to customize backgrounds, add overlays, integrate music, and more to make your stream unique.'
  }
];

function StreamNeoLandingPage() {
  const [email, setEmail] = useState('');
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [mode, setMode] = useState('light');

  // Create refs for each section
  const heroRef = useRef(null);
  const featuresRef = useRef(null);
  const performanceRef = useRef(null);
  const tutorialVideosRef = useRef(null);
  const pricingRef = useRef(null);
  const testimonialsRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const iframeRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const theme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: '#6a00ff',
      },
      background: {
        default: mode === 'dark' ? '#120329' : '#f4f4f4',
        paper: mode === 'dark' ? 'rgba(255,255,255,0.05)' : '#ffffff',
      },
      text: {
        primary: mode === 'dark' ? '#ffffff' : '#000000',
        secondary: mode === 'dark'
          ? 'rgba(255,255,255,0.7)'
          : 'rgba(0,0,0,0.7)',
      },
    },
    typography: {
      fontFamily: 'Inter, sans-serif',
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark'
              ? 'rgba(18, 3, 41, 0.9)'
              : 'rgba(255, 255, 255, 0.9)',
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          }
        }
      }
    }
  });
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  // Navigation menu items
  const navItems = [
    { label: 'Tutorials', ref: tutorialVideosRef },
    { label: 'Features', ref: featuresRef },
    { label: 'Pricing', ref: pricingRef },
    { label: 'Testimonials', ref: testimonialsRef }
  ];

  // Scroll to section function
  const scrollToSection = (elementRef) => {
    elementRef.current?.scrollIntoView({ behavior: 'smooth' });
    setIsMobileMenuOpen(false);
  };

  const navigateToExternal = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };


  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // Video enters the viewport, play with sound
          iframeRef.current?.contentWindow.postMessage(
            '{"event":"command","func":"playVideo","args":""}', "*"
          );
          iframeRef.current?.contentWindow.postMessage(
            '{"event":"command","func":"unMute","args":""}', "*"
          );
          setIsPlaying(true);
        } else {
          // Video exits the viewport, pause the video
          iframeRef.current?.contentWindow.postMessage(
            '{"event":"command","func":"pauseVideo","args":""}', "*"
          );
          setIsPlaying(false);
        }
      },
      { threshold: 0.5 } // Adjust threshold to control when it plays/pauses
    );

    if (iframeRef.current) {
      observer.observe(iframeRef.current);
    }

    return () => {
      if (iframeRef.current) {
        observer.unobserve(iframeRef.current);
      }
    };
  }, []);


  const ThemeToggle = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: theme.palette.text.primary
        }}
      >
        <Sun size={20} style={{ marginRight: 8 }} />
        <Switch
          checked={mode === 'dark'}
          onChange={() => setMode(mode === 'dark' ? 'light' : 'dark')}
          color="primary"
        />
        <Moon size={20} style={{ marginLeft: 8 }} />
      </Box>
    );
  };

  // Modify the Header to include ThemeToggle
  const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        setIsScrolled(window.scrollY > 50);
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
      <AppBar
        position="sticky"
        sx={{
          backdropFilter: isScrolled ? 'blur(10px)' : 'none',
          boxShadow: isScrolled ? '0 4px 6px rgba(0,0,0,0.1)' : 'none',
          transition: 'all 0.3s ease'
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between', py: 1 }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 800,
              background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            StreamNeo
          </Typography>

          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', gap: 3 }}>
            {navItems.map((item) => (
              <Button
                key={item.label}
                onClick={() => scrollToSection(item.ref)}
                sx={{
                  color: theme.palette.text.primary,
                  '&:hover': { color: '#6a00ff' }
                }}
              >
                {item.label}
              </Button>
            ))}
            {/* <ThemeToggle /> */}
            <Button
              variant="contained"
              onClick={() => navigateToExternal('https://app.streamneo.com/login')}
              sx={{
                bgcolor: '#6a00ff',
                color: 'white',
                px: 3,
                py: 1,
                borderRadius: '32px', // Fully rounded corners
                fontSize: isMobile ? '0.9rem' : '1.1rem',
                transition: 'all 0.3s ease', // Smooth transition for all properties
                transform: 'scale(1)', // Initial scale
                boxShadow: '0 4px 6px rgba(0,0,0,0.1)', // Subtle shadow
                '&:hover': {
                  bgcolor: '#5a00e6', // Slightly darker purple on hover
                  transform: 'scale(1.05)', // Slight scale-up on hover
                  boxShadow: '0 6px 8px rgba(0,0,0,0.2)', // Increased shadow on hover
                  background: 'linear-gradient(90deg, #6a00ff, #ff00ea)', // Gradient background on hover
                },
                '&:active': {
                  transform: 'scale(0.95)', // Slight scale-down when pressed
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Reduced shadow when pressed
                }
              }}
            >
              Login
            </Button>
          </Box>

          <IconButton
            sx={{ display: { md: 'none' }, color: theme.palette.text.primary }}
            onClick={() => setIsMobileMenuOpen(true)}
          >
            <Menu />
          </IconButton>
        </Toolbar>
      </AppBar>
    );
  };


  // Open video modal
  const openVideoModal = (video) => {
    setSelectedVideo(video);
    setIsVideoModalOpen(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const docHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollProgress = (scrollTop / docHeight) * 100;
      setScrollProgress(scrollProgress);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    console.log('Email submitted:', email);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          overflowX: 'hidden',
          position: 'relative',
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary
        }}
      >
        <Header />

        {/* Mobile Menu Drawer */}
        <Drawer
          anchor="right"
          open={isMobileMenuOpen}
          onClose={() => setIsMobileMenuOpen(false)}
          sx={{
            '& .MuiDrawer-paper': {
              width: '100%',
              bgcolor: theme.palette.background.default,
              color: 'white'
            }
          }}
        >
          <Box sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 800,
                  background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}
              >
                StreamNeo
              </Typography>
              <IconButton onClick={() => setIsMobileMenuOpen(false)} sx={{ color: 'white' }}>
                <ChevronDown />
              </IconButton>
            </Box>
            {navItems.map((item) => (
              <Button
                key={item.label}
                fullWidth
                onClick={() => scrollToSection(item.ref)}
                sx={{
                  justifyContent: 'flex-start',
                  color: 'white',
                  py: 2,
                  '&:hover': { bgcolor: 'rgba(106, 0, 255, 0.1)' }
                }}
              >
                {item.label}
              </Button>
            ))}
            <Button
              variant="contained"
              fullWidth
              onClick={() => navigateToExternal('https://app.streamneo.com/login')}
              sx={{
                mt: 2,
                bgcolor: '#6a00ff',
                color: 'white',
                '&:hover': { bgcolor: '#5a00e6' }
              }}
            >
              Get Started
            </Button>
          </Box>
        </Drawer>
        {/* Hero Section */}
        <Container
          maxWidth="lg"
          sx={{
            mt: 8,
            py: 8,
            color: 'white',
            textAlign: 'center'
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: 500,
              mb: 3,
              fontSize: isMobile ? '2.5rem' : '5.25rem',
              background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            24/7 YouTube Live Stream Pre-Recorded Videos
          </Typography>

          <Typography
            variant="h5"
            sx={{
              mb: 3,
              fontSize: isMobile ? '1.25rem' : '2.25rem',
              color: theme.palette.text.secondary
            }}
          >
            Take your YouTube channel to the next level with StreamNeo.
            The easiest way to build & maintain a 24 hour live stream using pre-recorded videos.
          </Typography>

          <Button
            variant="contained"
            onClick={() => navigateToExternal('https://app.streamneo.com/register')}
            sx={{
              bgcolor: '#6a00ff',
              color: 'white',
              px: 3,
              py: 1.5,
              borderRadius: '32px', // Fully rounded corners
              fontSize: isMobile ? '0.9rem' : '1.1rem',
              transition: 'all 0.3s ease', // Smooth transition for all properties
              transform: 'scale(1)', // Initial scale
              boxShadow: '0 4px 6px rgba(0,0,0,0.1)', // Subtle shadow
              '&:hover': {
                bgcolor: '#5a00e6', // Slightly darker purple on hover
                transform: 'scale(1.05)', // Slight scale-up on hover
                boxShadow: '0 6px 8px rgba(0,0,0,0.2)', // Increased shadow on hover
                background: 'linear-gradient(90deg, #6a00ff, #ff00ea)', // Gradient background on hover
              },
              '&:active': {
                transform: 'scale(0.95)', // Slight scale-down when pressed
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Reduced shadow when pressed
              }
            }}
          >
            Start Free Trial
          </Button>

          <Typography
            variant="body2"
            sx={{
              mt: 2,
              fontSize: isMobile ? '0.6rem' : '1rem',
              color: theme.palette.text.secondary
            }}
          >
            No credit card required
          </Typography>
        </Container>
        <Container
          ref={tutorialVideosRef}
          maxWidth="lg"
          sx={{ py: 12, bgcolor: theme.palette.background.default }}>
          <Typography
            variant="h3"
            align="center"
            sx={{
              mb: 8,
              color: theme.palette.text.primary,
              fontWeight: 800,
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -10,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 100,
                height: 4,
                bgcolor: '#6a00ff',
                borderRadius: 2
              }
            }}>Sample 24/7</Typography>
          {sampleTutorialVideos.map((video, index) => (
            <Grid
              container
              spacing={4}
              key={index}
              sx={{
                mb: 8,
                alignItems: 'center',
                flexDirection: index % 2 === 0 ? 'row' : 'row-reverse'
              }}
            >
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    pr: index % 2 === 0 ? 4 : 0,
                    pl: index % 2 !== 0 ? 4 : 0,
                    textAlign: index % 2 === 0 ? 'right' : 'left'
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      color: theme.palette.text.primary,
                      fontWeight: 800,
                      mb: 2,
                      background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent'
                    }}
                  >
                    {video.title}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      color: theme.palette.text.secondary,
                      mb: 3
                    }}>Explore a real-world example of a successful 24/7 live stream and see how StreamNeo can transform your content strategy.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  elevation={3}
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 4,
                    overflow: 'hidden',
                    bgcolor: 'rgba(255,255,255,0.05)',
                    border: '1px solid rgba(255,255,255,0.1)'
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      pt: '56.25%', // 16:9 aspect ratio
                      overflow: 'hidden'
                    }}
                  >
                    <iframe
                      width="100%"
                      height="100%"
                      src={`https://www.youtube.com/embed/${video.videoId}?autoplay=1&mute=1`}
                      title={video.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%'
                      }}
                    />
                  </Box>
                </Card>
              </Grid>
            </Grid>))}
        </Container>
        {/* Performance Stats Section */}
        <Container ref={performanceRef} maxWidth="lg" sx={{ py: 12, bgcolor: theme.palette.background.default }}>
          <Typography
            variant="h3"
            align="center"
            sx={{
              mb: 8,
              color: theme.palette.text.primary,
              fontWeight: 800,
            }}
          >
            How Live Videos Compare to Regular Videos
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {performanceStats.map((stat, index) => (
              <Grid item xs={12} md={3} key={index}>
                <Box
                  sx={{
                    bgcolor: 'rgba(255,255,255,0.05)',
                    p: 3,
                    borderRadius: 4,
                    textAlign: 'center',
                    border: '1px solid rgba(255,255,255,0.1)'
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      color: theme.palette.text.primary,
                      fontWeight: 700,
                      mb: 1
                    }}
                  >
                    {stat.value}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: theme.palette.text.secondary,
                      mb: 1
                    }}
                  >
                    {stat.label}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#00ff87',
                      fontWeight: 600
                    }}
                  >
                    +{stat.increase}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>

        <Box
          sx={{
            py: 12,
            bgcolor: theme.palette.background.default,
            color: 'white',
            textAlign: 'center'
          }}
        >
          <Container maxWidth="lg" sx={{
            flexDirection: 'row',
            display: 'flex',
            color: 'white',
            textAlign: 'center',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Typography
              variant="h2"
              sx={{
                fontWeight: 800,
                flex: 1,

                background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                WebkitBackgroundClip: 'text',
                textAlign: 'start',
                WebkitTextFillColor: 'transparent'
              }}
            >
              Get More Exposure With a 24/7 YouTube Live Stream
            </Typography>
            <div style={{ width: '30px', height: '10px' }} />
            <Typography
              variant="h4"
              sx={{
                flex: 1,
                color: theme.palette.text.secondary,
                maxWidth: 800,
                textAlign: 'start',
                mx: 'auto'
              }}
            >
              Get on top of the search since live videos have priority over regular ones, making it easier for you to get more views, followers & profit.
            </Typography>
          </Container>
        </Box>
        <Container
          ref={tutorialVideosRef}
          maxWidth="lg"
          sx={{ py: 12, bgcolor: theme.palette.background.default }}>
          <Typography
            variant="h3"
            align="center"
            sx={{
              mb: 8,
              color: theme.palette.text.primary,
              fontWeight: 800,
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -10,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 100,
                height: 4,
                bgcolor: '#6a00ff',
                borderRadius: 2
              }
            }}>Tutorial 101</Typography>
          {livestreamTutorialVideos.map((video, index) => (
            <Grid
              container
              spacing={4}
              key={index}
              sx={{
                mb: 8,
                alignItems: 'center',
                flexDirection: 'row-reverse'
              }}
            >
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    pr: 4,
                    pl: 4,
                    textAlign: 'left'
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      color: theme.palette.text.primary,
                      fontWeight: 800,
                      mb: 2,
                      background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent'
                    }}
                  >
                    {video.title}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      color: theme.palette.text.secondary,
                      mb: 3
                    }}>Explore a real-world example of a successful 24/7 live stream and see how StreamNeo can transform your content strategy.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  elevation={3}
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 4,
                    overflow: 'hidden',
                    bgcolor: 'rgba(255,255,255,0.05)',
                    border: '1px solid rgba(255,255,255,0.1)'
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      pt: '56.25%', // 16:9 aspect ratio
                      overflow: 'hidden'
                    }}
                  >
                    <iframe
                      ref={iframeRef}
                      width="100%"
                      height="100%"
                      src={`https://www.youtube.com/embed/${video.videoId}?enablejsapi=1`}
                      title={video.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </Box>
                </Card>
              </Grid>
            </Grid>))}
        </Container>
        <Box ref={featuresRef} sx={{ py: 12, bgcolor: theme.palette.background.default }}>
          <Container maxWidth="lg">
            <Typography
              variant="h3"
              align="center"
              sx={{
                mb: 8,
                color: theme.palette.text.primary,
                fontWeight: 800,
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: -10,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: 100,
                  height: 4,
                  bgcolor: '#6a00ff',
                  borderRadius: 2
                }
              }}
            >
              Powerful Streaming Features
            </Typography>

            <Grid container spacing={4}>
              {extendedFeatures.map((feature, index) => (
                <Grid item xs={12} md={3} key={index}>
                  <Card
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      p: 3,
                      borderRadius: 4,
                      bgcolor: 'rgba(255,255,255,0.05)',
                      border: '1px solid rgba(255,255,255,0.1)',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-10px)'
                      }
                    }}
                  >
                    <Box sx={{ mb: 3, display: 'flex', justifyContent: 'center' }}>
                      {feature.icon}
                    </Box>
                    <Typography
                      variant="h6"
                      sx={{
                        color: theme.palette.text.primary,
                        fontWeight: 600,
                        mb: 2,
                        textAlign: 'center',
                        minHeight: '3rem'
                      }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.text.secondary,
                        textAlign: 'center'
                      }}
                    >
                      {feature.description}
                    </Typography>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        {/* Pricing Section */}
        <Box ref={pricingRef} sx={{ py: 12, bgcolor: theme.palette.background.default }}>
          <Container maxWidth="lg">
            <Typography
              variant="h3"
              align="center"
              sx={{
                mb: 8,
                color: theme.palette.text.primary,
                fontWeight: 800,
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: -10,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: 100,
                  height: 4,
                  bgcolor: '#6a00ff',
                  borderRadius: 2
                }
              }}
            >
              Flexible Pricing Plans
            </Typography>

            <Grid container spacing={4}>
              {pricingPlans.slice(0, 4).map((plan, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Card
                    elevation={plan.recommended ? 6 : 3}
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      p: 3,
                      borderRadius: 6,
                      bgcolor: 'rgba(255,255,255,0.05)',
                      border: plan.recommended
                        ? '2px solid #6a00ff'
                        : '1px solid rgba(106, 0, 255, 0.1)',
                      boxShadow: plan.recommended
                        ? '0 15px 30px rgba(106, 0, 255, 0.2)'
                        : '0 10px 20px rgba(0,0,0,0.05)'
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                      <Typography variant="h5" sx={{ color: theme.palette.text.primary, fontWeight: 700 }}>
                        {plan.name}
                      </Typography>
                      {plan.savings && (
                        <Typography
                          variant="body2"
                          sx={{
                            bgcolor: '#00ff87',
                            color: 'black',
                            px: 1,
                            py: 0.5,
                            borderRadius: 2
                          }}
                        >
                          SAVE {plan.savings}
                        </Typography>
                      )}
                    </Box>
                    {plan.price !== null ? (
                      <>
                        <Typography variant="h4" sx={{ mb: 3, color: '#6a00ff', fontWeight: 800 }}>
                          ₹{plan.price}/slot
                        </Typography>
                        {plan.features.map((feature, i) => (
                          <Box
                            key={i}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              mb: 1.5,
                              color: theme.palette.text.primary
                            }}
                          >
                            <Check size={20} color="#6a00ff" style={{ marginRight: 8 }} />
                            <Typography variant="body2">{feature}</Typography>
                          </Box>
                        ))}
                        <div style={{ height: '20px', width: '10px' }} />
                        <Button
                          variant="contained"
                          onClick={() => navigateToExternal('https://app.streamneo.com/register')}
                          fullWidth
                          sx={{
                            bgcolor: '#6a00ff',
                            color: 'white',
                            px: 3,
                            py: 1,
                            borderRadius: '32px', // Fully rounded corners
                            fontSize: isMobile ? '0.9rem' : '1.1rem',
                            transition: 'all 0.3s ease', // Smooth transition for all properties
                            transform: 'scale(1)', // Initial scale
                            boxShadow: '0 4px 6px rgba(0,0,0,0.1)', // Subtle shadow
                            '&:hover': {
                              bgcolor: '#5a00e6', // Slightly darker purple on hover
                              transform: 'scale(1.05)', // Slight scale-up on hover
                              boxShadow: '0 6px 8px rgba(0,0,0,0.2)', // Increased shadow on hover
                              background: 'linear-gradient(90deg, #6a00ff, #ff00ea)', // Gradient background on hover
                            },
                            '&:active': {
                              transform: 'scale(0.95)', // Slight scale-down when pressed
                              boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Reduced shadow when pressed
                            }
                          }}
                        >
                          Get Started
                        </Button>
                      </>
                    ) : (
                      <Box>
                        <Typography variant="body1" sx={{ color: theme.palette.text.primary, mb: 2 }}>
                          {plan.description}
                        </Typography>
                        <Button
                          variant="outlined"
                          fullWidth
                          sx={{
                            borderColor: '#6a00ff',
                            color: '#6a00ff',
                            '&:hover': {
                              bgcolor: 'rgba(106, 0, 255, 0.1)'
                            }
                          }}
                        >
                          Contact Us
                        </Button>
                      </Box>
                    )}
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        {/* Testimonials Section */}
        <Box ref={testimonialsRef} sx={{ py: 12, bgcolor: theme.palette.background.default }}>
          <Container maxWidth="lg">
            <Typography
              variant="h3"
              align="center"
              sx={{
                mb: 8,
                color: theme.palette.text.primary,
                fontWeight: 800,
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: -10,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: 100,
                  height: 4,
                  bgcolor: '#6a00ff',
                  borderRadius: 2
                }
              }}
            >
              What Our Users Say
            </Typography>

            <Grid container spacing={4}>
              {testimonials.map((testimonial, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Card
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      p: 3,
                      borderRadius: 4,
                      bgcolor: 'rgba(255,255,255,0.05)',
                      border: '1px solid rgba(255,255,255,0.1)'
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Avatar
                        sx={{
                          bgcolor: '#6a00ff',
                          color: 'white',
                          mr: 2,
                          width: 48,
                          height: 48
                        }}
                      >
                        {testimonial.name[0]}
                      </Avatar>
                      <Box>
                        <Typography variant="body1" sx={{ color: theme.palette.text.primary, fontWeight: 600 }}>
                          {testimonial.name}
                        </Typography>
                        <Box sx={{ display: 'flex' }}>
                          {[...Array(testimonial.rating)].map((_, i) => (
                            <Star key={i} size={20} color="#00ff87" fill="#00ff87" />
                          ))}
                        </Box>
                      </Box>
                    </Box>
                    <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                      "{testimonial.quote}"
                    </Typography>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
        <Box
          sx={{
            py: 12,
            bgcolor: theme.palette.background.default,
            color: 'white'
          }}
        >
          <Container maxWidth="lg">
            <Typography
              variant="h3"
              align="center"
              sx={{
                mb: 8,
                fontWeight: 800,
                color: theme.palette.text.primary,
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: -10,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: 100,
                  height: 4,
                  bgcolor: '#6a00ff',
                  borderRadius: 2
                }
              }}
            >
              Frequently Asked Questions
            </Typography>

            {faqs.map((faq, index) => (
              <Accordion
                key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
                sx={{
                  bgcolor: 'rgba(255,255,255,0.05)',
                  color: theme.palette.text.primary,
                  mb: 2,
                  borderRadius: 2,
                  '&:before': {
                    display: 'none'
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ChevronDown color="white" />}
                  sx={{
                    '& .MuiAccordionSummary-content': {
                      color: theme.palette.text.primary
                    }
                  }}
                >
                  <Typography variant="h6">{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                    {faq.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Container>
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default StreamNeoLandingPage;