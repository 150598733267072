import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Container,
    Paper,
    Typography,
    Box,
    Avatar,
    TextField,
    Button,
    Grid,
    Divider,
    IconButton,
    Snackbar,
    Alert,
    InputAdornment
} from '@mui/material';
import {
    User,
    Mail,
    Phone,
    Edit2,
    Save,
    X,
    Key,
    Lock
} from 'lucide-react';

const ProfileScreen = () => {
    // Get user data from localStorage
    const [userData, setUserData] = useState({
        name: localStorage.getItem("name") || '',
        email: localStorage.getItem("email") || '',
        mobile: localStorage.getItem("mobile") || '',
        userId: localStorage.getItem("userId") || ''
    });

    const [editing, setEditing] = useState(false);
    const [formData, setFormData] = useState({ ...userData });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const [changePassword, setChangePassword] = useState(false);
    const [passwordData, setPasswordData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    const handleEditToggle = () => {
        if (editing) {
            // If we're closing edit mode, reset form data
            setFormData({ ...userData });
        }
        setEditing(!editing);
        setChangePassword(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswordData({
            ...passwordData,
            [name]: value
        });
    };

    const handleSubmit = async () => {
        try {
            // API call would go here
            // const response = await updateUserProfile(formData);

            // Update localStorage with new values
            localStorage.setItem("name", formData.name);
            localStorage.setItem("email", formData.email);
            localStorage.setItem("mobile", formData.mobile);

            // Update state
            setUserData({ ...formData });
            setEditing(false);

            // Show success message
            setSnackbar({
                open: true,
                message: 'Profile updated successfully!',
                severity: 'success'
            });
        } catch (error) {
            console.error("Profile update error:", error);
            setSnackbar({
                open: true,
                message: 'Failed to update profile. Please try again.',
                severity: 'error'
            });
        }
    };

    const handlePasswordSubmit = async () => {
        // Validate password match
        if (passwordData.newPassword !== passwordData.confirmPassword) {
            setSnackbar({
                open: true,
                message: 'New passwords do not match',
                severity: 'error'
            });
            return;
        }

        try {
            // API call would go here
            // const response = await updatePassword(passwordData);

            setChangePassword(false);
            setPasswordData({
                currentPassword: '',
                newPassword: '',
                confirmPassword: ''
            });

            setSnackbar({
                open: true,
                message: 'Password updated successfully!',
                severity: 'success'
            });
        } catch (error) {
            console.error("Password update error:", error);
            setSnackbar({
                open: true,
                message: 'Failed to update password. Please try again.',
                severity: 'error'
            });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Container maxWidth="xl" sx={{ py: 4 }}>
            <Paper
                elevation={0}
                sx={{
                    borderRadius: '24px',
                    overflow: 'hidden',
                    mb: 4,
                    boxShadow: '0 4px 20px rgba(0,0,0,0.05)'
                }}
            >
                {/* Header Section */}
                <Box
                    sx={{
                        p: { xs: 3, md: 4 },
                        background: 'linear-gradient(135deg, #6366F1 0%, #8B5CF6 100%)',
                        color: 'white',
                        position: 'relative'
                    }}
                >
                    <Typography variant="h5" sx={{ fontWeight: 700, mb: 1 }}>
                        Profile Information
                    </Typography>
                    <Typography variant="body1" sx={{ opacity: 0.9, mb: 2 }}>
                        Manage your personal information and account settings
                    </Typography>
                </Box>

                {/* Profile Content */}
                <Box sx={{ p: { xs: 3, md: 4 } }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                        <Avatar
                            sx={{
                                width: 80,
                                height: 80,
                                background: 'linear-gradient(45deg, #6366F1 30%, #8B5CF6 90%)',
                                fontSize: '2rem',
                                fontWeight: 700,
                                mr: 3
                            }}
                        >
                            {userData.name.charAt(0)}
                        </Avatar>
                        <Box>
                            <Typography variant="h5" sx={{ fontWeight: 700 }}>
                                {userData.name}
                            </Typography>
                            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                {userData.email}
                            </Typography>
                        </Box>
                    </Box>

                    <Divider sx={{ my: 3 }} />

                    {!editing ? (
                        // View Mode
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary', mb: 1 }}>
                                        Full Name
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                                        <User size={18} style={{ marginRight: 8, opacity: 0.7 }} />
                                        {userData.name}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary', mb: 1 }}>
                                        Email Address
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                                        <Mail size={18} style={{ marginRight: 8, opacity: 0.7 }} />
                                        {userData.email}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary', mb: 1 }}>
                                        Phone Number
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                                        <Phone size={18} style={{ marginRight: 8, opacity: 0.7 }} />
                                        {userData.mobile || 'Not provided'}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary', mb: 1 }}>
                                        User ID
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                                        <Key size={18} style={{ marginRight: 8, opacity: 0.7 }} />
                                        {userData.userId}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    startIcon={<Lock size={18} />}
                                    onClick={async () => {
                                        try {
                                            const response = await axios.post('https://app.streamneo.com/forgot-password', { email: userData.email });
                                            setSnackbar({
                                                open: true,
                                                message: response.data.message,
                                                severity: 'success'
                                            });
                                        } catch (err) {
                                            setSnackbar({
                                                open: true,
                                                message: err.response?.data?.error || 'An error occurred',
                                                severity: 'error'
                                            });
                                        }
                                    }}
                                    sx={{
                                        mt: 2,
                                        borderColor: '#6366F1',
                                        color: '#6366F1',
                                        '&:hover': {
                                            borderColor: '#4F46E5',
                                            backgroundColor: 'rgba(99, 102, 241, 0.04)'
                                        }
                                    }}
                                >
                                    Reset Password
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        // Edit Mode
                        <Box component="form">
                            {!changePassword ? (
                                // Profile Edit Form
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Full Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <User size={20} color="#666" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '12px',
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Email Address"
                                            name="email"
                                            type="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Mail size={20} color="#666" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '12px',
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Phone Number"
                                            name="mobile"
                                            value={formData.mobile}
                                            onChange={handleChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Phone size={20} color="#666" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '12px',
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="User ID"
                                            value={userData.userId}
                                            disabled
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Key size={20} color="#666" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '12px',
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                                            <Button
                                                variant="contained"
                                                startIcon={<Save size={18} />}
                                                onClick={handleSubmit}
                                                sx={{
                                                    bgcolor: '#6366F1',
                                                    '&:hover': {
                                                        bgcolor: '#4F46E5',
                                                    }
                                                }}
                                            >
                                                Save Changes
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                onClick={() => {

                                                }}
                                                startIcon={<Lock size={18} />}
                                                sx={{
                                                    borderColor: '#6366F1',
                                                    color: '#6366F1',
                                                    '&:hover': {
                                                        borderColor: '#4F46E5',
                                                        backgroundColor: 'rgba(99, 102, 241, 0.04)'
                                                    }
                                                }}
                                            >
                                                Reset Password
                                            </Button>
                                            <Button
                                                variant="text"
                                                onClick={handleEditToggle}
                                                sx={{
                                                    color: 'text.secondary',
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            ) : (
                                // Password Change Form
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                                            Reset Password
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            type="password"
                                            label="Current Password"
                                            name="currentPassword"
                                            value={passwordData.currentPassword}
                                            onChange={handlePasswordChange}
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Lock size={20} color="#666" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '12px',
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ height: 20 }} /> {/* Spacer */}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            type="password"
                                            label="New Password"
                                            name="newPassword"
                                            value={passwordData.newPassword}
                                            onChange={handlePasswordChange}
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Lock size={20} color="#666" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '12px',
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            type="password"
                                            label="Confirm New Password"
                                            name="confirmPassword"
                                            value={passwordData.confirmPassword}
                                            onChange={handlePasswordChange}
                                            required
                                            error={passwordData.confirmPassword !== '' && passwordData.newPassword !== passwordData.confirmPassword}
                                            helperText={passwordData.confirmPassword !== '' && passwordData.newPassword !== passwordData.confirmPassword ? "Passwords don't match" : ""}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Lock size={20} color="#666" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '12px',
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                                            <Button
                                                variant="contained"
                                                startIcon={<Save size={18} />}
                                                onClick={handlePasswordSubmit}
                                                disabled={!passwordData.currentPassword || !passwordData.newPassword || !passwordData.confirmPassword}
                                                sx={{
                                                    bgcolor: '#6366F1',
                                                    '&:hover': {
                                                        bgcolor: '#4F46E5',
                                                    }
                                                }}
                                            >
                                                Update Password
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                onClick={() => setChangePassword(false)}
                                                sx={{
                                                    borderColor: '#6366F1',
                                                    color: '#6366F1',
                                                    '&:hover': {
                                                        borderColor: '#4F46E5',
                                                        backgroundColor: 'rgba(99, 102, 241, 0.04)'
                                                    }
                                                }}
                                            >
                                                Back to Profile
                                            </Button>
                                            <Button
                                                variant="text"
                                                onClick={handleEditToggle}
                                                sx={{
                                                    color: 'text.secondary',
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                    )}
                </Box>
            </Paper>

            {/* Snackbar for notifications */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ProfileScreen;