import React, { useState, useEffect } from 'react';
import {
    Container,
    Paper,
    Typography,
    Box,
    Grid,
    Button,
    Divider,
    TextField,
    Snackbar,
    Alert
} from '@mui/material';
import {
    WalletOutlined as WalletIcon,
    AddCircleOutline as AddIcon,
    PaymentOutlined as PaymentIcon
} from '@mui/icons-material';
import { useRazorpay } from "react-razorpay";

const WalletScreen = () => {
    const [balance, setBalance] = useState(0);
    const [amount, setAmount] = useState(0);
    const [customAmount, setCustomAmount] = useState("");
    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState([]);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const { Razorpay } = useRazorpay();

    // Predefined topup options
    const topupOptions = [500, 1000, 5000];

    useEffect(() => {
        fetchWalletData();
    }, []);

    const fetchWalletData = async () => {
        setLoading(true);
        try {
            // Fetch wallet balance
            const balanceResponse = await fetch("https://app.streamneo.com/user/wallet-balance", {
                headers: { Authorization: localStorage.getItem("token") }
            });
            const balanceData = await balanceResponse.json();
            setBalance(balanceData.balance);
        } catch (error) {
            console.error("Error fetching wallet data:", error);
            setSnackbar({
                open: true,
                message: 'Failed to load wallet information',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleAmountSelect = (selectedAmount) => {
        setAmount(selectedAmount);
        setCustomAmount("");
    };

    const handleCustomAmountChange = (e) => {
        const value = e.target.value;
        setCustomAmount(value);
        if (value && !isNaN(value)) {
            setAmount(Number(value));
        }
    };

    const handleAddMoneyToWallet = async () => {
        try {
            const orderResponse = await fetch("https://app.streamneo.com/create-wallet-order", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token"),
                },
                body: JSON.stringify({ amount }),
            });

            const orderData = await orderResponse.json();
            if (!orderResponse.ok) throw new Error(orderData.message);

            const options = {
                key: "rzp_test_IUYIAAIps3HYr2",
                amount: orderData.order.amount,
                currency: orderData.order.currency,
                name: "StreamNeo",
                description: "Wallet Recharge",
                order_id: orderData.order.id,
                handler: async (response) => {
                    try {
                        const verificationResponse = await fetch("https://app.streamneo.com/verify-wallet-payment", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: localStorage.getItem("token"),
                            },
                            body: JSON.stringify({
                                paymentId: response.razorpay_payment_id,
                                orderId: response.razorpay_order_id,
                                signature: response.razorpay_signature,
                            }),
                        });

                        const verificationData = await verificationResponse.json();
                        if (!verificationResponse.ok) throw new Error(verificationData.message);

                        // Refresh wallet data after successful recharge
                        fetchWalletData();

                        setSnackbar({
                            open: true,
                            message: 'Wallet recharged successfully!',
                            severity: 'success'
                        });
                    } catch (error) {
                        console.error("Payment verification failed:", error);
                        setSnackbar({
                            open: true,
                            message: 'Payment verification failed. Please try again.',
                            severity: 'error'
                        });
                    }
                },
                prefill: {
                    name: localStorage.getItem("name"),
                    email: localStorage.getItem("email"),
                    contact: localStorage.getItem("mobile"),
                },
            };

            const razorpay = new Razorpay(options);
            razorpay.open();
        } catch (error) {
            console.error("Error creating wallet order:", error);
            setSnackbar({
                open: true,
                message: 'Something went wrong. Please try again.',
                severity: 'error'
            });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Container maxWidth="xl" sx={{ py: 4 }}>
            <Paper
                elevation={0}
                sx={{
                    borderRadius: '24px',
                    overflow: 'hidden',
                    mb: 4,
                    boxShadow: '0 4px 20px rgba(0,0,0,0.05)'
                }}
            >
                {/* Header Section */}
                <Box
                    sx={{
                        p: { xs: 3, md: 4 },
                        background: 'linear-gradient(135deg, #6366F1 0%, #8B5CF6 100%)',
                        color: 'white',
                        position: 'relative'
                    }}
                >
                    <Typography variant="h5" sx={{ fontWeight: 700, mb: 1 }}>
                        Wallet Management
                    </Typography>
                    <Typography variant="body1" sx={{ opacity: 0.9, mb: 2 }}>
                        Manage your wallet balance and transaction history
                    </Typography>
                </Box>

                {/* Wallet Content */}
                <Box sx={{ p: { xs: 3, md: 4 } }}>
                    {/* Current Balance */}
                    <Paper
                        elevation={0}
                        sx={{
                            p: 3,
                            borderRadius: '16px',
                            background: 'linear-gradient(135deg, rgba(99,102,241,0.1) 0%, rgba(139,92,246,0.1) 100%)',
                            mb: 4
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    backgroundColor: 'rgba(99,102,241,0.1)',
                                    borderRadius: '50%',
                                    p: 2,
                                    mr: 3
                                }}
                            >
                                <WalletIcon
                                    sx={{
                                        fontSize: 40,
                                        color: '#6366F1'
                                    }}
                                />
                            </Box>
                            <Box>
                                <Typography variant="h6" sx={{ fontWeight: 500 }}>
                                    Current Balance
                                </Typography>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontWeight: 700,
                                        color: '#6366F1'
                                    }}
                                >
                                    ₹{balance}
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>

                    {/* Recharge Section */}
                    <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
                        Add Money to Wallet
                    </Typography>

                    <Grid container spacing={3} sx={{ mb: 4 }}>
                        {topupOptions.map((option) => (
                            <Grid item xs={12} sm={4} key={option}>
                                <Paper
                                    elevation={0}
                                    onClick={() => handleAmountSelect(option)}
                                    sx={{
                                        p: 3,
                                        borderRadius: '16px',
                                        border: amount === option
                                            ? '1px solid #6366F1'
                                            : '1px solid transparent',
                                        background: amount === option
                                            ? 'linear-gradient(135deg, rgba(99,102,241,0.05) 0%, rgba(99,102,241,0.1) 100%)'
                                            : 'linear-gradient(135deg, rgba(0,0,0,0.02) 0%, rgba(0,0,0,0.05) 100%)',
                                        cursor: 'pointer',
                                        transition: 'all 0.3s ease',
                                        '&:hover': {
                                            transform: 'translateY(-5px)',
                                            boxShadow: '0 4px 15px rgba(0,0,0,0.1)'
                                        }
                                    }}
                                >
                                    <Box sx={{ textAlign: 'center' }}>
                                        <AddIcon
                                            sx={{
                                                fontSize: 32,
                                                color: amount === option ? '#6366F1' : 'text.secondary',
                                                mb: 2
                                            }}
                                        />
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                fontWeight: 600,
                                                color: amount === option ? '#6366F1' : 'text.primary'
                                            }}
                                        >
                                            ₹{option}
                                        </Typography>
                                    </Box>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>

                    {/* Custom Amount Input */}
                    <Paper
                        elevation={0}
                        sx={{
                            p: 3,
                            borderRadius: '16px',
                            background: 'linear-gradient(135deg, rgba(0,0,0,0.02) 0%, rgba(0,0,0,0.05) 100%)',
                            mb: 4
                        }}
                    >
                        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
                            Or Enter Custom Amount
                        </Typography>
                        <TextField
                            fullWidth
                            label="Custom Amount"
                            type="number"
                            value={customAmount}
                            onChange={handleCustomAmountChange}
                            InputProps={{
                                startAdornment: <Typography sx={{ mr: 1 }}>₹</Typography>
                            }}
                            variant="outlined"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '12px'
                                }
                            }}
                        />
                    </Paper>

                    {/* Add Money Button */}
                    <Box sx={{ textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            disabled={amount <= 0}
                            onClick={handleAddMoneyToWallet}
                            startIcon={<PaymentIcon />}
                            sx={{
                                px: 4,
                                py: 1.5,
                                borderRadius: '12px',
                                background: 'linear-gradient(135deg, #6366F1 0%, #8B5CF6 100%)',
                                '&:disabled': {
                                    background: 'rgba(0,0,0,0.12)'
                                }
                            }}
                        >
                            Add ₹{amount}
                        </Button>
                    </Box>

                    {/* Transaction History */}
                    <Divider sx={{ my: 4 }} />
                    <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
                        Transaction History
                    </Typography>
                    {transactions.length === 0 ? (
                        <Typography variant="body2" color="text.secondary" align="center">
                            No transactions found
                        </Typography>
                    ) : (
                        <Grid container spacing={2}>
                            {transactions.map((transaction, index) => (
                                <Grid item xs={12} key={index}>
                                    <Paper
                                        elevation={0}
                                        sx={{
                                            p: 2,
                                            borderRadius: '12px',
                                            background: 'rgba(0,0,0,0.02)',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Box>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                                {transaction.description}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {new Date(transaction.date).toLocaleString()}
                                            </Typography>
                                        </Box>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontWeight: 600,
                                                color: transaction.type === 'credit' ? '#4CAF50' : '#F44336'
                                            }}
                                        >
                                            {transaction.type === 'credit' ? '+' : '-'}₹{transaction.amount}
                                        </Typography>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Box>
            </Paper>

            {/* Snackbar for notifications */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default WalletScreen;