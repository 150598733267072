import React, { useState, useEffect } from 'react';
import {
    ThemeProvider,
    createTheme,
    CssBaseline,
    useMediaQuery
} from '@mui/material';
import {
    Container,
    Box,
    Typography,
    Button,
    Grid,
    Card,
    CardContent,
    CardMedia,
    AppBar,
    Toolbar,
    IconButton,
    CircularProgress,
    Chip
} from '@mui/material';
import { Menu, ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

function BlogsPage() {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [mode, setMode] = useState('light');
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const theme = createTheme({
        palette: {
            mode: mode,
            primary: {
                main: '#6a00ff',
            },
            background: {
                default: mode === 'dark' ? '#120329' : '#f4f4f4',
                paper: mode === 'dark' ? 'rgba(255,255,255,0.05)' : '#ffffff',
            },
            text: {
                primary: mode === 'dark' ? '#ffffff' : '#000000',
                secondary: mode === 'dark'
                    ? 'rgba(255,255,255,0.7)'
                    : 'rgba(0,0,0,0.7)',
            },
        },
        typography: {
            fontFamily: 'Inter, sans-serif',
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: mode === 'dark'
                            ? 'rgba(18, 3, 41, 0.9)'
                            : 'rgba(255, 255, 255, 0.9)',
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    }
                }
            }
        }
    });

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    // Fetch blogs data
    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                setLoading(true);
                const response = await fetch('https://admin.streamneo.com/api/blogs');
                if (!response.ok) {
                    throw new Error('Failed to fetch blogs');
                }
                const data = await response.json();
                setBlogs(Array.isArray(data) ? data : [data]);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    // Extract text content from HTML string
    const extractTextFromHtml = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || '';
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const handleBlogClick = (blog) => {
        navigate(`/blog/${blog._id}`, { state: { blog } });
    };

    const Header = () => {
        const [isScrolled, setIsScrolled] = useState(false);

        useEffect(() => {
            const handleScroll = () => {
                setIsScrolled(window.scrollY > 50);
            };

            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }, []);

        return (
            <AppBar
                position="sticky"
                sx={{
                    backdropFilter: isScrolled ? 'blur(10px)' : 'none',
                    boxShadow: isScrolled ? '0 4px 6px rgba(0,0,0,0.1)' : 'none',
                    transition: 'all 0.3s ease'
                }}
            >
                <Toolbar sx={{ justifyContent: 'space-between', py: 1 }}>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 800,
                            background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate('/')}
                    >
                        StreamNeo
                    </Typography>

                    <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', gap: 3 }}>
                        <Button
                            sx={{
                                color: theme.palette.text.primary,
                                '&:hover': { color: '#6a00ff' }
                            }}
                            onClick={() => navigate('/')}
                        >
                            Home
                        </Button>
                        <Button
                            sx={{
                                color: '#6a00ff',
                                fontWeight: 'bold',
                                '&:hover': { color: '#ff00ea' }
                            }}
                        >
                            Blog
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => window.location.href = 'https://app.streamneo.com/login'}
                            sx={{
                                bgcolor: '#6a00ff',
                                color: 'white',
                                px: 3,
                                py: 1,
                                borderRadius: '32px',
                                fontSize: isMobile ? '0.9rem' : '1.1rem',
                                transition: 'all 0.3s ease',
                                transform: 'scale(1)',
                                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                '&:hover': {
                                    bgcolor: '#5a00e6',
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 6px 8px rgba(0,0,0,0.2)',
                                    background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                                },
                                '&:active': {
                                    transform: 'scale(0.95)',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                }
                            }}
                        >
                            Login
                        </Button>
                    </Box>

                    <IconButton
                        sx={{ display: { md: 'none' }, color: theme.palette.text.primary }}
                        onClick={() => setIsMobileMenuOpen(true)}
                    >
                        <Menu />
                    </IconButton>
                </Toolbar>
            </AppBar>
        );
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box
                sx={{
                    minHeight: '100vh',
                    overflowX: 'hidden',
                    position: 'relative',
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary
                }}
            >
                <Header />

                <Container maxWidth="lg" sx={{ mt: 8, mb: 12 }}>
                    <Box sx={{ textAlign: 'center', mb: 8 }}>
                        <Typography
                            variant="h2"
                            sx={{
                                fontWeight: 700,
                                mb: 2,
                                background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                            }}
                        >
                            StreamNeo Blog
                        </Typography>
                        <Typography
                            variant="h5"
                            sx={{
                                color: theme.palette.text.secondary,
                                maxWidth: 800,
                                mx: 'auto'
                            }}
                        >
                            Insights, tutorials, and updates to help you master 24/7 live streaming
                        </Typography>
                    </Box>

                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', my: 8 }}>
                            <CircularProgress sx={{ color: '#6a00ff' }} />
                        </Box>
                    ) : error ? (
                        <Box sx={{ textAlign: 'center', my: 8 }}>
                            <Typography variant="h6" color="error">
                                {error}
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{ mt: 2 }}
                                onClick={() => window.location.reload()}
                            >
                                Try Again
                            </Button>
                        </Box>
                    ) : (
                        <Grid container spacing={4}>
                            {blogs.map((blog) => (
                                <Grid item xs={12} md={6} lg={4} key={blog._id.$oid}>
                                    <Card
                                        sx={{
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            borderRadius: 3,
                                            overflow: 'hidden',
                                            boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                            '&:hover': {
                                                transform: 'translateY(-8px)',
                                                boxShadow: '0 16px 32px rgba(0,0,0,0.16)',
                                                cursor: 'pointer'
                                            }
                                        }}
                                        onClick={() => handleBlogClick(blog)}
                                    >
                                        <CardMedia
                                            component="img"
                                            height="200"
                                            image={`https://admin.streamneo.com/${blog.thumbnail}`}
                                            alt={blog.title}
                                        />
                                        <CardContent sx={{ flexGrow: 1, p: 3 }}>
                                            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Chip
                                                    label={blog.category}
                                                    size="small"
                                                    sx={{
                                                        bgcolor: 'rgba(106, 0, 255, 0.1)',
                                                        color: '#6a00ff',
                                                        fontWeight: 600
                                                    }}
                                                />
                                                <Typography variant="caption" color="text.secondary">
                                                    {blog.createdAt && formatDate(blog.createdAt)}
                                                </Typography>
                                            </Box>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    fontWeight: 700,
                                                    mb: 1,
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: 2,
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden'
                                                }}
                                            >
                                                {blog.title}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                sx={{
                                                    mb: 2,
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: 3,
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden'
                                                }}
                                            >
                                                {blog.description || extractTextFromHtml(blog.content).substring(0, 120) + '...'}
                                            </Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 'auto' }}>
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        fontWeight: 600,
                                                        color: '#6a00ff'
                                                    }}
                                                >
                                                    Read More
                                                </Typography>
                                                <ArrowRight size={16} color="#6a00ff" style={{ marginLeft: 4 }} />
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Container>
                <Footer />
            </Box>
        </ThemeProvider>
    );
}

export default BlogsPage;